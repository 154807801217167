<template>
    <div class="">
        
        <Header></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
                <div class="container-fluid">

                    <!-- Header -->
                    <div class="page-header">

                        <!-- Title -->
                        <h2 class="header-title text-capitalize"
                            :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                            <i class="mdi mdi-cellphone"></i>
                            {{ $t('projects.pgSMS') }}
                        </h2>
                        <router-link :to="{ name: refs }" 
                              class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
                        </router-link>

                        <!-- Role -->
                        <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                            {{ auth.role }}
                        </label>
                        <!-- End Role -->

                        <div class="clearfix"></div>

                        <!-- Breadcrumb -->
                        <nav class="breadcrumb breadcrumb-dash"
                            :class="(locale == 'ar') ? 'dir-rtl' : ''">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="breadcrumb-item">
                                <i class="ti-home p-r-5"></i>
                                {{ $t('projects.dashboard') }}
                            </router-link>
                            <span class="breadcrumb-item active">{{ $t('projects.pgSMS') }}</span>
                        </nav>
                        <!-- End Breadcrumb -->
                       
                    </div>  
                    <!-- End Header -->

            <div v-if="pgLoading" class="row h-100">
                <div class="container text-center">
                    <div class="loader loader-lg ui-mtop-15"></div>
                </div>
            </div> 
                        
            <form v-if="!pgLoading" @submit.prevent="addNew" enctype="multipart/form-data"> 
                <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">

                    <div class="col-md-12">

                        <!-- Content -->
                        <div class="card">
                            <div class="row">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-11 ml-auto mr-auto">

                                                <div class="p-v-10">

                                                    <div class=" form-group">
                                                        <label>{{ $t('projects.smsUsers') }}</label>
                                                        <div v-if="usersLoading" class="container text-center">
                                                            <div class="loader loader-lg ui-mtop-15"></div>
                                                        </div>
                                                        <multiselect v-if="!usersLoading"
                                                            v-model="value" 
                                                            :options="options" 
                                                            :multiple="true" 
                                                            :close-on-select="false" 
                                                            :clear-on-select="false" 
                                                            :preserve-search="true" 
                                                            :placeholder="$t('projects.sms_addNew')" 
                                                            :tag-placeholder="$t('projects.sms_placeholder')"
                                                            label="name" 
                                                            track-by="name" 
                                                            :preselect-first="false"
                                                            :taggable="true" 
                                                            style="direction: rtl; text-align: right"
                                                            @tag="addTag">
                                                        </multiselect>
                                                      <!-- <textarea class="form-control" 
                                                          style="direction: ltr;margin-top:10px;font-family: arial" 
                                                          rows="10" 
                                                          v-model="row.phones" required=""></textarea> -->
                                                    </div>

                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.sms_lang') }}</label>
                                                        <select style="width: 25%;margin-bottom: 10px" 
                                                              class="form-control" 
                                                              v-model="row.lang">
                                                            <option value="ar">ar</option>
                                                            <option value="en">en</option>
                                                        </select>

                                                        <label class="control-label">{{ $t('projects.sms_content') }}</label>
                                                        <textarea 
                                                            class="form-control" 
                                                            rows="10" 
                                                            v-model="row.sms"
                                                            required="">
                                                          </textarea>
                                                    </div>
                                                    
                                                </div>
                                   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Content -->


                        <!-- Button -->
                        <div class="form-group">
                            <button class="btn btn-dark btn-size" :disabled="btnLoading">
                                <span v-if="btnLoading">
                                    <div class="loader loader-xs loader-center"></div>
                                </span>
                                <span v-if="!btnLoading">{{ $t('projects.send') }}</span>
                            </button>
                        </div>  
                        <!-- End Buttons -->

                    </div>

                    

                </div>
            </form>


                </div>
            </div>

        <Footer></Footer>
        </div>

    </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import iziToast from 'izitoast';

  export default {
    name: 'Create',
    components: {
      Header,
      QuickView,
      Footer
    },
    mounted() {},
    data() {
      return {
        //
        auth: {
            access_token: '',
            role: '',
        },
        row: {
            lang: 'ar',
            sms: '',
            phones: '',
            checked: false,
        },

          value: [],
          options: [],
          usersLoading: true,

        editor: {
            api_key: window.editor_apiKey,
            menubar: window.editor_menubar,
            plugins:[window.editor_plugins],
            toolbar: window.editor_toolbar,
        },

        pgLoading: false,
        btnLoading: false,

        locale: 'ar',
        refs: 'sms',
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if(localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if(localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      } 

      this.fetchUsers();
      //
    },
    methods: {

         addTag(newTag) {
            const tag = {
                name: newTag,
                language: newTag
            }
            this.options.push(tag)
            this.value.push(tag)
         },

         fetchUsers() {
            this.usersLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/cellphones',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: true,
                    paginate: 10000
                }
            }
            this.$axios(options)
            .then(res => {
                this.usersLoading = false;
                this.options = res.data.rows;
            })
            .catch(() => {})
            .finally(() => {});
        },

        // add New
        addNew() {
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/cellphones',
                method: 'POST',
                data: {
                    // row
                    lang: this.row.lang,
                    sms: this.row.sms,
                    //phones: this.row.phones,
                    values: this.value,
                }
            }
        
            this.$axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: '',
                    message: 'تم إرسالة الرسالة بنجاح',
                });
                //this.$router.push({ name: this.refs });
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });

                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
            })
            .finally(() => { })
        },

        // remove LocalStorage
        removeLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('avatar');
            localStorage.removeItem('role');
        },

        isChecked() {
          if(this.row.checked) {
            this.row.phones = '';
            this.row.checked = false;
          } else {
            this.row.checked = true;

            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/cellphones',
                method: 'GET',
                data: {},
                params: {},
            }
            this.axios(options)
            .then(res => {
                this.row.phones = res.data.data;
            })
            .catch(() => {})
            .finally(() => {});
          }
        },

    },
  }
</script>

<!-- New step! Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>