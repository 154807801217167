<template>

    <div class="p-v-20">

      <div class="p-v-20 row">

        <div class="form-group col-6">
          <label class="control-label">{{ $t("seo.title_ar") }}</label>
          <input type="text" 
            class="form-control" 
            v-model="row.title_ar"
            v-on:change="onInputChange"
            />
        </div>

        <div class="form-group col-6">
          <label class="control-label">{{ $t("seo.title_en") }}</label>
          <input type="text" 
            class="form-control"
            v-model="row.title_en" 
            v-on:change="onInputChange"
            />

        </div>
 

        <div class="form-group col-6">
          <label class="control-label">{{ $t("seo.description_ar") }}</label>
          <textarea
            type="text"
            class="form-control"
            v-model="row.description_ar"
            v-on:change="onInputChange"
            />
        </div>

            <div class="form-group col-6">
          <label class="control-label">{{ $t("seo.description_en") }}</label>
          <textarea
            type="text"
            class="form-control"
            v-model="row.description_en"
            v-on:change="onInputChange"
            />
        </div>
 
 

        <div class="form-group col-6">
          <label class="control-label">{{ $t("seo.keywords_ar") }}</label>
          <textarea type="text" 
            class="form-control" 
            v-model="row.keywords_ar"
            v-on:change="onInputChange"
            />
        </div>


        <div class="form-group col-6">
          <label class="control-label">{{ $t("seo.keywords_en") }}</label>
          <textarea type="text" 
            class="form-control" 
            v-model="row.keywords_en" 
            v-on:change="onInputChange"
            />
        </div>


      </div>
    </div>

</template>

<script>
export default {
  name: "Seo",
  props: [
    "title_ar",
    "title_en",
    "description_ar",
    "description_en",
    "keywords_ar",
    "keywords_en",
  ],
  components: {},
  data() {
    return {
 
       row: {
        title_ar: this.$props.title_ar,
        title_en: this.$props.title_en,
        description_ar: this.$props.description_ar,
        description_en: this.$props.description_en,
        keywords_ar: this.$props.keywords_ar,
        keywords_en: this.$props.keywords_en,
       }
    


    };
  },
  methods: {
        onInputChange(){
            this.$emit('SeoChange', this.row);
        },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
