<template>
    <div class="">
        
        <Header></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
                <div class="container-fluid">

                    <!-- Header -->
                    <div class="page-header">

                        <!-- Title -->
                        <h2 class="header-title text-capitalize"
                          :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                          <i class="mdi mdi-human-handsup"></i>
                          {{ $t('projects.cashDonations') }}
                      </h2>
                        <router-link :to="{ name: refs }" 
                              class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
                        </router-link>

                        <!-- Role -->
                        <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                            {{ auth.role }}
                        </label>
                        <!-- End Role -->

                        <div class="clearfix"></div>

                        <!-- Breadcrumb -->
                        <nav class="breadcrumb breadcrumb-dash"
                            :class="(locale == 'ar') ? 'dir-rtl' : ''">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="breadcrumb-item">
                                <i class="ti-home p-r-5"></i>
                                {{ $t('projects.dashboard') }}
                            </router-link>
                            <router-link :to="{ name: refs }" 
                                class="breadcrumb-item text-capitalize">{{ $t('projects.cashDonations') }}
                            </router-link>
                            <span class="breadcrumb-item active">{{ $t('projects.add_new') }}</span>
                        </nav>
                        <!-- End Breadcrumb -->
                       
                    </div>  
                    <!-- End Header -->

            <div v-if="pgLoading" class="row h-100">
                <div class="container text-center">
                    <div class="loader loader-lg ui-mtop-15"></div>
                </div>
            </div> 
                        
            <form v-if="!pgLoading" @submit.prevent="addNew" enctype="multipart/form-data"> 
                <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">

                    <div class="col-md-12">

                        <!-- Content -->
                        <div class="card">
                            <div class="row">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-11 ml-auto mr-auto">

                                            <div class="p-v-20 row">

                                                <div class="col-6">
                                                    <div class="form-group col-12">
                                                        <label class="control-label">
                                                            {{ $t('projects.choose_cat') }}
                                                        </label>
                                                        <select 
                                                            class="form-control" 
                                                            v-model="row.model_type"
                                                            @change="fetchElements()">
                                                            <option value="orphans">
                                                                {{ $t('nav.orphans') }}
                                                            </option>
                                                            <option value="preachers">
                                                                {{ $t('nav.preachers') }}
                                                            </option>
                                                            <option value="postgraduates">
                                                                {{ $t('nav.postgraduates') }}
                                                            </option>
                                                            <option value="projects">
                                                                {{ $t('nav.projects') }}
                                                            </option>
                                                            <option value="reliefs">
                                                                {{ $t('nav.reliefs') }}
                                                            </option>
                                                            <option value="endowments">
                                                                {{ $t('nav.endowments') }}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div v-if="row.model_type" class="form-group col-12">
                                                        <label class="control-label">
                                                            {{ $t('projects.choose_element') }}
                                                        </label>
                                                        <div v-if="elementLoading" class="text-center">
                                                            <div class="loader loader-xs"></div>
                                                        </div>
                                                        <select 
                                                            v-if="!elementLoading" 
                                                            class="form-control" 
                                                            v-model="row.model_id"
                                                            @change="fetchRow()">
                                                            <option 
                                                                v-for="(el, idx) in elements"
                                                                :key="idx" 
                                                                :value="el.id">
                                                                {{ (el.title) ? el.title : el.name }}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div v-if="fetchLoading" class="text-center col-12">
                                                        <div class="loader loader-xs"></div>
                                                    </div>
                                                    <div v-if="!fetchLoading && fetch" 
                                                        class="form-group col-6">
                                                        <div class="form-group">
                                                            <img v-if="fetch.image"
                                                                :src="(fetch.image) ? fetch.image.url : ''" 
                                                                style="width: 70%;height: 250px">
                                                        </div>

                                                        <div v-if="
                                                            row.model_type == 'orphans' ||
                                                            row.model_type == 'preachers' ||
                                                            row.model_type == 'postgraduates'">
                                                            <div v-if="fetch.name">
                                                                <h5>{{ $t('projects.orp_name') }}</h5>
                                                                <p>{{ fetch.name }}</p>
                                                            </div>

                                                            <div v-if="fetch.comb_number">
                                                                <h5>{{ $t('projects.theNumberId') }}</h5>
                                                                <p>{{ fetch.comb_number }}</p>
                                                            </div>

                                                            <div v-if="fetch.mother_name">
                                                                <h5>{{ $t('projects.mother_name') }}</h5>
                                                                <p>{{ fetch.mother_name }}</p>
                                                            </div>

                                                            <div v-if="fetch.geneder_name">
                                                                <h5>{{ $t('projects.geneder') }}</h5>
                                                                <p>
                                                                {{ 
                                                                    (fetch.geneder_name == 'Female') 
                                                                        ? 'انثي' : 'ذكر'  }}
                                                                </p>
                                                            </div>

                                                            <div v-if="fetch.birth_date">
                                                                <h5>{{ $t('projects.birth_date') }}</h5>
                                                                <p> {{ fetch.birth_date }}</p>
                                                            </div>

                                                            <div v-if="fetch.birth_place">
                                                                <h5>{{ $t('projects.birth_place') }}</h5>
                                                                <p> {{ fetch.birth_place }}</p>
                                                            </div>

                                                        </div>

                                                        <div v-if="
                                                            row.model_type == 'projects' || 
                                                            row.model_type == 'reliefs' ||
                                                            row.model_type == 'endowments'">
                                                            <div v-if="fetch.title">
                                                                <h5>{{ $t('projects.pro_name') }}</h5>
                                                                <p>{{ fetch.ar.title }}</p>
                                                            </div>

                                                            <div v-if="fetch.comb_number">
                                                                <h5>{{ $t('projects.theNumberId') }}</h5>
                                                                <p>{{ fetch.comb_number }}</p>
                                                            </div>

                                                            <div v-if="fetch.body">
                                                                <h5>{{ $t('projects.pro_desc') }}</h5>
                                                                <p v-html="fetch.ar.body"></p>
                                                            </div>


                                                            <div v-if="fetch.country">
                                                                <h5>{{ $t('projects.country') }} - {{ $t('projects.city') }}</h5>
                                                                <p> 
                                                                    {{ fetch.country.translations[1].name }} - 
                                                                    {{ fetch.city.translations[1].name }}
                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group col-12">
                                                        <label class="control-label">
                                                            {{ $t('projects.cash_amount') }}
                                                        </label>
                                                        <input type="text" class="form-control" v-model="row.amount" required="">
                                                    </div>
                                                </div>



                                                </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Content -->


                        <!-- Button -->
                        <div class="form-group">
                            <button class="btn btn-dark btn-size" :disabled="btnLoading">
                                <span v-if="btnLoading">
                                    <div class="loader loader-xs loader-center"></div>
                                </span>
                                <span v-if="!btnLoading">{{ $t('projects.create') }}</span>
                            </button>
                            <button type="button" @click="cancel" :disabled="btnLoading"
                                class="btn btn-default btn-size p-10">
                                {{ $t('projects.cancel') }}
                            </button>
                        </div>  
                        <!-- End Buttons -->

                    </div>


                </div>
            </form>


                </div>
            </div>

        <Footer></Footer>
        </div>

    </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import iziToast from 'izitoast';

  export default {
    name: 'Create',
    components: {
      Header,
      QuickView,
      Footer,
    },
    mounted() {},
    data() {
      return {
        //
        auth: {
            access_token: '',
            role: '',
        },
        row: {
            model_id: '',
            model_type: '',
            amount: '',
        },
        editor: {
            api_key: window.editor_apiKey,
            menubar: window.editor_menubar,
            plugins:[window.editor_plugins],
            toolbar: window.editor_toolbar,
        },

        elementLoading: false,
        elements: [],

        fetchLoading: false,
        fetch: [],

        pgLoading: false,
        btnLoading: false,
        isClicked: true,
        locale: 'ar',
        refs: 'cashDonations'
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if(localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if(localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      }

      //
    },
    methods: {

        fetchElements() {
            this.elementLoading = true;
            this.model_id = '';
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            var side = '';
            if(this.row.model_type == 'orphans') {
                side = 'orphans';
            } else if(this.row.model_type == 'preachers') {
                side = 'preachers';
            } else if(this.row.model_type == 'postgraduates') {
                side = 'postgraduates';
            } else if(this.row.model_type == 'projects') {
                side = 'projects';
            } else if(this.row.model_type == 'reliefs') {
                side = 'reliefs';
            } else if(this.row.model_type == 'endowments') {
                side = 'endowments';
            }
            const options = {
                url: window.baseURL+'/'+side,
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: "active",
                    paginate: 1000
                }
            }
            this.$axios(options)
            .then(res => {
                this.elementLoading = false;
                this.elements = res.data.rows;
            })
            .catch(() => {})
            .finally(() => {});
        },

        fetchRow() {
            this.fetchLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            var side = '';
            if(this.row.model_type == 'orphans') {
                side = 'orphans';
            } else if(this.row.model_type == 'preachers') {
                side = 'preachers';
            } else if(this.row.model_type == 'postgraduates') {
                side = 'postgraduates';
            } else if(this.row.model_type == 'projects') {
                side = 'projects';
            } else if(this.row.model_type == 'reliefs') {
                side = 'reliefs';
            } else if(this.row.model_type == 'endowments') {
                side = 'endowments';
            }
            const options = {
                url: window.baseURL+'/'+side+'/'+this.row.model_id,
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: "active",
                }
            }
            this.$axios(options)
            .then(res => {
                this.fetchLoading = false;
                this.fetch = res.data.row;
            })
            .catch(() => {})
            .finally(() => {});
        },

        // add New
        addNew() {
            this.isClicked = false; // disabled alert msg
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/'+this.refs,
                method: 'POST',
                data: {
                    model_id: this.row.model_id,
                    model_type: this.row.model_type,
                    amount: this.row.amount
                }
            }
        
            this.$axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: '',
                    message: 'تم الإضافة بنجاح',
                });
                this.$router.push({ name: this.refs });
                this.isClicked = true; // enabled alert msg
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });

                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
                this.isClicked = true; // enabled alert msg
            })
            .finally(() => { })
        },

        // remove LocalStorage
        removeLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('avatar');
            localStorage.removeItem('role');
        },

        cancel() {
            if(confirm('هل تريد المغادرة ؟')) {
                this.isClicked=false;
                this.$router.push({ name: this.refs })
            }
        },
    },

        beforeRouteLeave(to, from, next) {
            if(this.isClicked) {
                if(confirm('هل تريد المغادرة ؟')) {
                    next();
                }
            } else {
                next();
            }
        },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>