<template>
  <div class="">
    <Header v-on:headerToChild="onSearchSubmit"></Header>

    <div class="page-container">
      <QuickView></QuickView>

      <!-- Content Wrapper START -->
      <div class="main-content">
        <div class="container-fluid">
          <!-- Header -->
          <div class="page-header">
            <h2
              class="header-title text-capitalize"
              :class="locale == 'ar' ? 'dir-rtl go-right' : ''"
            >
              <i class="mdi mdi-briefcase-check"></i>
              {{ $t("app.applications") }}
            </h2>

            <!-- Role -->
            <label
              class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right"
            >
              {{ auth.role }}
            </label>
            <!-- End Role -->

            <div class="clearfix"></div>

            <!-- Breadcrumb -->
            <nav
              class="breadcrumb breadcrumb-dash"
              :class="locale == 'ar' ? 'dir-rtl' : ''"
            >
              <router-link
                :to="{ name: 'dashboard' }"
                class="breadcrumb-item"
                :class="locale == 'ar' ? 'dir-rtl' : ''"
              >
                <i class="ti-home p-r-5"></i>
                {{ $t("app.dashboard") }}
              </router-link>
              <span class="breadcrumb-item text-capitalize active">{{
                $t("app.applications")
              }}</span>
            </nav>
            <!-- End Breadcrumb -->
          </div>
          <!-- End Header -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="loader loader-lg ui-mtop-15"></div>
            </div>
          </div>
          <!-- End Loading -->

          <!-- Apps -->
          <div
            v-if="!pgLoading"
            class="row"
            :class="locale == 'ar' ? 'dir-rtl' : ''"
          >
            <div
              class="col-md-3"
              v-for="(row, index) in rows"
              :key="index"
              :class="row.authority ? '' : 'hidden'"
            >
              <div class="card text-center">
                <div class="card-header">
                  <h4>{{ row.name }}</h4>
                </div>
                <div class="ui-mb10">
                  <span
                    v-if="!row.setup"
                    class="btn btn-rounded btn-bold text-uppercase"
                    :class="row.setup ? ' btn-success ' : ' btn-danger '"
                  >
                    <span :class="row.icon"></span>
                    <span v-html="$t('projects.update')" class="almarai">
                    </span>
                  </span>
                  <router-link
                    v-if="row.setup"
                    :to="{ name: row.slug }"
                    class="btn btn-rounded btn-bold text-uppercase"
                    :class="row.setup ? ' btn-success ' : ' btn-danger '"
                  >
                    <span :class="row.icon"></span>
                    <span v-html="$t('projects.update')" class="almarai">
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- End Apps -->
        </div>
      </div>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import QuickView from "../layouts/QuickView.vue";
import Footer from "../layouts/Footer.vue";
import iziToast from "izitoast";

export default {
  name: "List",
  components: {
    Header,
    QuickView,
    Footer,
  },
  data() {
    return {
      // auth
      auth: {
        access_token: "",
        role: "",
      },

      //
      locale: "ar",
      search: "",

      rows: [],
      show: 100,
      pgLoading: true,

      //
      refs: "applications",
    };
  },
  mounted() {},
  watch: {
    $route() {
      // fetch Data
      this.fetchData("", true);
    },
  },
  computed: {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    if (localStorage.getItem("locale")) {
      this.locale = localStorage.getItem("locale");
    }

    // fetch Data
    this.fetchData();
  },
  methods: {
    // fetchData
    fetchData() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs,
        method: "GET",
        data: {},
        params: {
          locale: this.locale,
          search: this.search,
          status: "active",
          pagiante: 100,
        },
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          // rows & paginate
          this.rows = res.data.rows;
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 403) {
            this.removeLocalStorage();
            this.$router.push({ name: "forbidden" });
          } else {
            this.pgLoading = false;
            iziToast.warning({
              icon: "ti-ban",
              title: "Wow, man",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    // Search Submit
    onSearchSubmit(value) {
      this.search = value;
      this.fetchData("", true);
    },

    // remove LocalStorage
    removeLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("avatar");
      localStorage.removeItem("role");
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>