<template>
    <div class="">
        
      <Header></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
                <div class="container-fluid">

                    <!-- Header -->
                    <div class="page-header">

                        <!-- Title -->
                        <h2 class="header-title text-capitalize"
                            :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                      <i class="mdi mdi-settings"></i>
                      {{ $t('projects.donateNow') }}
                  </h2>
                        <router-link :to="{ name: refs }" 
                              class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
                        </router-link>

                        <!-- Role -->
                        <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                            {{ auth.role }}
                        </label>
                        <!-- End Role -->

                        <div class="clearfix"></div>

                        <!-- Breadcrumb -->
                        <nav class="breadcrumb breadcrumb-dash"
                             :class="(locale == 'ar') ? 'dir-rtl' : ''">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="breadcrumb-item">
                                <i class="ti-home p-r-5"></i>
                                {{ $t('projects.dashboard') }}
                            </router-link>
                            <router-link :to="{ name: 'settings' }" 
                                class="breadcrumb-item text-capitalize active">
                                {{ $t('projects.settings') }}
                            </router-link>
                            <span class="breadcrumb-item text-capitalize active">{{ $t('projects.donateNow') }}</span>
                        </nav>
                        <!-- End Breadcrumb -->
                       
                    </div>  
                    <!-- End Header -->

                <div v-if="pgLoading" class="row h-100">
                    <div class="container text-center">
                        <div class="loader loader-lg ui-mtop-15"></div>
                    </div>
                </div> 

                <form v-if="!pgLoading" @submit.prevent="editRow" enctype="multipart/form-data"> 
                    <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">
                        <div class="col-md-8">

                            <!-- Content -->
                            <div class="card">
                                <div class="row">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-11 ml-auto mr-auto">
                                                
                                                
                                                <div class="p-v-20">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.donateNow') }} [ EN ]</label>
                                                        <input type="text"
                                                                class="form-control" 
                                                                v-model="row.name_en">
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.donateNow') }} [ AR ]</label>
                                                        <input type="text"
                                                                class="form-control" 
                                                                v-model="row.name_ar">
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.link') }}</label>
                                                        <input type="text"
                                                                class="form-control" 
                                                                style="direction: ltr" 
                                                                v-model="row.link">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- End Content -->

                            <!-- Button -->
                            <div class="form-group">
                                <button class="btn btn-dark btn-size" :disabled="btnLoading">
                                    <span v-if="btnLoading">
                                        <div class="loader loader-xs loader-center"></div>
                                    </span>
                                    <span v-if="!btnLoading">
                                        {{ (edit) ? $t('projects.update')  : $t('projects.create') }}
                                    </span>
                                </button>
                                <button type="button" @click="cancel" :disabled="btnLoading"
                                    class="btn btn-default btn-size p-10">
                                    {{ $t('projects.cancel') }}
                                </button>
                            </div>  
                            <!-- End Buttons -->

                        </div>
                        <div class="col-md-4">

                        <!-- Status -->
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <div class="m-b-10">  
                                        <label class="control-label">{{ $t('projects.status') }}</label>
                                    </div>   
                                    <div class="radio d-inline m-r-15">
                                        <input id="statusRadio1" 
                                            type="radio"
                                            v-model="row.status"
                                            value="1" 
                                            :checked="row.status">
                                        <label for="statusRadio1"> {{ $t('projects.active') }} </label>
                                    </div>
                                    <div class="radio d-inline m-r-15">
                                        <input id="statusRadio2" 
                                            type="radio"
                                            v-model="row.status"
                                            value="0"
                                            :checked="row.status">
                                        <label for="statusRadio2"> {{ $t('projects.inactive') }} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Status -->

                    </div>
                    </div>
                </form>




                <!-- Table -->
                 <div class="card">
                 <div class="card-body">
                    <div class="table-responsive">
                      <table id="printMe" class="table" :class="(locale == 'ar') ? 'dir-rtl' : ''">
                        <thead>
                           <tr class="top-border-none">
                              <th scope="col" style="width: 10%">#</th>
                              <th scope="col" style="width: 20%">{{ $t('projects.name') }}</th>
                              <th scope="col" class="text-center" style="width: 20%">{{ $t('projects.link') }}</th>
                              <th scope="col" class="text-center" style="width: 20%">{{ $t('projects.date') }}</th>
                              <th scope="col" class="text-center" style="width: 20%">{{ $t('projects.actions') }}</th>
                            </tr>
                        </thead>
                          
                          <!-- Loader -->
                          <tr v-if="dataLoading">
                            <td colspan="5" class="text-center ui-h100">
                              <div class="loader loader-lg ui-mtop-15"></div>
                            </td>
                          </tr>

                          <!-- No data found -->
                          <tr v-if="!dataLoading && !rows.length">
                            <td colspan="5" class="text-center ui-h100">
                                <span>{{ $t('projects.noData') }}</span>
                            </td>
                          </tr>

                        <!-- Rows -->
                        <tbody v-if="!dataLoading && rows.length">
                          <tr v-for="(row, index) in rows" :key="index"
                            style="font-weight: 700">

                            <td>#</td>

                            <!-- Name -->
                            <td class="font-weight-semi-bold">
                              <span class="fweight-600">{{ row.name_ar }}</span>
                            </td>
                            <!-- End Name -->

                            <td class="text-center ui-date dir-ltr">
                                {{ row.link }}
                            </td>
                            
                            <!-- Date -->
                            <td v-html="(row.deleted_at) 
                                ? row.deleted_at :(row.updated_at) ? row.updated_at : row.created_at"
                                class="text-center ui-date dir-ltr">
                            </td>
                            <!-- End Date -->

                             <!-- Action -->
                            <td class="text-center">
                              <div class="dropdown">
                                <a href="javascript:;" data-toggle="dropdown" class="text-black f20">
                                  <span v-if="!row.loading" class="ti-more"></span> 
                                  <div v-if="row.loading" 
                                    class="loader loader-xs" 
                                    style="margin-top: -15px;margin-left: -12px">
                                  </div>
                                </a>
                                <div class="dropdown-menu ui-min-w120">
                                  <a href="javascript:;" @click="showEdit(row)" 
                                        class="dropdown-item">{{ $t('app.edit') }}
                                  </a>
                                  <a @click="row.loading = true; deletePermanently(row.id)"
                                      class="dropdown-item" href="javascript:;">{{ $t('app.deletePermanently') }}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <!-- End Action -->

                          </tr>
                        </tbody>
                      </table>
                    </div>


                      <paginate v-if="!dataLoading && rows.length !== 0"
                          :page-count="paginateCount"
                          :prev-text="'Prev'"
                          :next-text="'Next'"
                          :container-class="'pagination'"
                          :click-handler="fetchData">
                      </paginate>

                    </div>
                </div>

                <!-- End Table -->


                </div>
            </div>

          <Footer></Footer>
        </div>

  </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import iziToast from 'izitoast';

  export default {
    name: 'Settings',
    components: {
      Header,
      QuickView,
      Footer,
    },
    mounted() {},
    data() {
      return {
        //
        auth: {
            access_token: '',
            role: '',
        },
        row: {
            link: '',
            name_en: '',
            name_ar: '',
            status: 1,
        },

        pgLoading: true,

        dataLoading: false,
        rows: [],
        pagination: {},
        paginateCount: 10,

        btnLoading: false,
        locale: 'ar',
        isClicked: true,
        edit:false,
        edit_id: '',
        refs: 'link_tables',
        //refs_id: 32,
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if(localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if(localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      }

      //
      this.fetchData();
    },
    methods: {

      // fetch Row
      fetchData() {
        //this.pgLoading = true;
        this.dataLoading = true;
        this.axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            'Authorization': `Bearer ` + this.auth.access_token,
        };
        const options = {
            url: window.baseURL+'/'+this.refs,
            method: 'GET',
            data: {}
        }
        let vm = this;
        this.$axios(options)
            .then(res => {
                this.pgLoading = false;
                this.edit = false;

                // row
                this.dataLoading = false;
                this.rows = res.data.rows;
                if(res.data.paginate.total) {
                  vm.makePagination(res.data.paginate);
                  this.paginateCount = Math.ceil(res.data.paginate.total / 10);
                }
                // this.row.link = res.data.row.link;
                // this.row.title_en = res.data.row.en.title;
                // this.row.title_ar = res.data.row.ar.title;
                // this.row.status = res.data.row.status;

            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.pgLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });                
                }
            })
            .finally(() => {});
      },

      makePagination(meta) {
        let pagination = {
            current_page: meta.current_page,
            last_page: meta.last_page,
            next_page_url: meta.next_page_url,
            prev_page_url: meta.prev_page_url
        }
        this.pagination = pagination;
      },

      showEdit(row) {
            this.edit_id = row.encrypt_id;
            this.row.name_en = row.name_en;
            this.row.name_ar = row.name_ar;
            this.row.link = row.link;
            this.row.status = row.status;
            this.edit = true;
      },

      // edit Row
      editRow() {
        this.isClicked = false; // disabled alert msg
        this.btnLoading = true;
        this.axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            'Authorization': `Bearer ` + this.auth.access_token,
        };
        const options = {
            url: (this.edit) ? window.baseURL+'/'+this.refs+'/'+this.edit_id : window.baseURL+'/'+this.refs,
            method: (this.edit) ? 'PUT' : 'POST',
            data: {
                link: this.row.link,
                name_en: this.row.name_en,
                name_ar: this.row.name_ar,
                status: this.row.status,
            }
        }
        
        this.$axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: '',
                    message: 'Item Updated Successfully',
                });
                //this.$router.push({ name: this.refs });
                this.clearInputs();
                this.fetchData();
                this.isClicked = true; // enabled alert msg
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });

                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
                this.isClicked = true; // enabled alert msg
            })
            .finally(() => { })
        },

        clearInputs() {
            this.row.name_en = '';
            this.row.name_ar = '';
            this.row.link = '';
        },

        // remove LocalStorage
        removeLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('avatar');
            localStorage.removeItem('role');
        },

   

        cancel() {
            if(confirm('هل تريد المغادرة ؟')) {
                this.$router.push({ name: this.refs })
            }
        },


        deletePermanently(id) {
                if(confirm('Are You Sure?')) {
                    this.axios.defaults.headers.common = {
                        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                        'Authorization': `Bearer `+this.auth.access_token,
                    };
                    const options = {
                        url: window.baseURL+'/'+this.refs+'/'+id,
                        method: 'DELETE',
                        data: {},
                    }
                  this.axios(options)
                    .then(() => {
                      this.fetchData(); 
                      iziToast.success({
                          icon: 'ti-check',
                          title: '',
                          message: 'تم الحذف بنجاح',
                      });
                        
                    })
                    .catch(() => {
                        this.fetchData(); 
                        iziToast.error({
                            icon: 'ti-na',
                            title: '',
                            message: 'حدث خطأ في الخادم, من فضلك حاول مرة آخري',
                        });
                    })
                    .finally(() => {});
                } else { 
                    // In case Cancel Alert
                    this.fetchData(); 
                }
            },

    },

        beforeRouteLeave(to, from, next) {
            if(this.isClicked) {
                if(confirm('هل تريد المغادرة ؟')) {
                    next();
                }
            }
        },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>