<template>
    <div class="">
      <Header></Header>

        <div class="page-container">
            <QuickView></QuickView>
          
            
            <div class="main-content">
                <div class="container-fluid">
                    <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">

                        
                        <!-- 2. Card -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">
                                    <div class="dropdown pull-right show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(packageDays == '0') ? 'active' : ''"
                                                @click="fetchTotalPackages(0)">{{ $t('dashboard.today') }}</span>
                                            <span class="dropdown-item "
                                                :class="(packageDays == '1') ? 'active' : ''"
                                                @click="fetchTotalPackages(1)">{{ $t('dashboard.yesterday') }}</span>
                                            <span class="dropdown-item "
                                                :class="(packageDays == '7') ? 'active' : ''"
                                                @click="fetchTotalPackages(7)">{{ $t('dashboard.last7days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(packageDays == '28') ? 'active' : ''"
                                                @click="fetchTotalPackages(28)">{{ $t('dashboard.last28days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(packageDays == '90') ? 'active' : ''"
                                                @click="fetchTotalPackages(90)">{{ $t('dashboard.last90days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(packageDays == '180') ? 'active' : ''"
                                                @click="fetchTotalPackages(180)">{{ $t('dashboard.last180days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(packageDays == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalPackages('infinity')">{{ $t('dashboard.lastCalendar') }}</span>
                                        </div>
                                    </div>
                                    <div class="media align-items-center py-2"
                                        :class="(locale == 'ar') ? 'dir-rtl' : ''">
                                        <div class="media-body">
                                            <h5 class="h6 text-muted mb-3" style="font-size:0.9rem">{{ $t('dashboard.total_donations') }} </h5>
                                            <span v-if="packageLoading">
                                                <div class="loader loader-sm"></div>
                                            </span>
                                            <span v-if="!packageLoading" 
                                                class="h4 font-weight-normal mb-0">{{ packageTotal }}
                                            </span>
                                        </div>
                                        <div v-if="!packageLoading" 
                                            class="text-right ml-2" 
                                            style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ packagePercentage }}
                                                <span :class="packageArrow"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 2. End Card -->

                         <!-- 5. Card -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">
                                    <div class="dropdown pull-right show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuTotalPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(amountDays == '0') ? 'active' : ''"
                                                @click="fetchTotalAmounts(0)">{{ $t('dashboard.today') }}</span>
                                            <span class="dropdown-item "
                                                :class="(amountDays == '1') ? 'active' : ''"
                                                @click="fetchTotalAmounts(1)">{{ $t('dashboard.yesterday') }}</span>
                                            <span class="dropdown-item "
                                                :class="(amountDays == '7') ? 'active' : ''"
                                                @click="fetchTotalAmounts(7)">{{ $t('dashboard.last7days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(amountDays == '28') ? 'active' : ''"
                                                @click="fetchTotalAmounts(28)">{{ $t('dashboard.last28days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(amountDays == '90') ? 'active' : ''"
                                                @click="fetchTotalAmounts(90)">{{ $t('dashboard.last90days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(amountDays == '180') ? 'active' : ''"
                                                @click="fetchTotalAmounts(180)">{{ $t('dashboard.last180days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(amountDays == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalAmounts('infinity')">{{ $t('dashboard.lastCalendar') }}</span>
                                        </div>
                                    </div>
                                    <div class="media align-items-center py-2"
                                        :class="(locale == 'ar') ? 'dir-rtl' : ''">
                                        <div class="media-body">
                                            <h5 class="h6 text-muted mb-3" style="font-size:0.9rem">{{ $t('dashboard.total_amounts') }}</h5>
                                            <span v-if="amountLoading">
                                                <div class="loader loader-sm"></div>
                                            </span>
                                            <span v-if="!amountLoading" 
                                                class="h4 font-weight-normal mb-0">{{ amountTotal }}
                                            </span>
                                        </div>
                                        <div v-if="!amountLoading" 
                                            class="text-right ml-2" 
                                            style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ amountPercentage }}
                                                <span :class="amountArrow"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 5. End Card -->

                        <!-- 1. Card -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">
                                    <div class="dropdown pull-right show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(visitorDays == '0') ? 'active' : ''"
                                                @click="fetchTotalVisitors(0)">{{ $t('dashboard.today') }}</span>
                                            <span class="dropdown-item "
                                                :class="(visitorDays == '1') ? 'active' : ''"
                                                @click="fetchTotalVisitors(1)">{{ $t('dashboard.yesterday') }}</span>
                                            <span class="dropdown-item "
                                                :class="(visitorDays == '7') ? 'active' : ''"
                                                @click="fetchTotalVisitors(7)">{{ $t('dashboard.last7days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(visitorDays == '28') ? 'active' : ''"
                                                @click="fetchTotalVisitors(28)">{{ $t('dashboard.last28days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(visitorDays == '90') ? 'active' : ''"
                                                @click="fetchTotalVisitors(90)">{{ $t('dashboard.last90days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(visitorDays == '180') ? 'active' : ''"
                                                @click="fetchTotalVisitors(180)">{{ $t('dashboard.last180days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(visitorDays == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalVisitors('infinity')">{{ $t('dashboard.lastCalendar') }}</span>
                                        </div>
                                    </div>
                                    <div class="media align-items-center py-2"
                                        :class="(locale == 'ar') ? 'dir-rtl' : ''">
                                        <div class="media-body">
                                            <h5 class="h6 text-muted mb-3" style="font-size:0.9rem">
                                                {{ $t('dashboard.total_visitors') }}
                                            </h5>
                                            <span v-if="visitorLoading">
                                                <div class="loader loader-sm"></div>
                                            </span>
                                            <span v-if="!visitorLoading" 
                                                class="h4 font-weight-normal mb-0">{{ visitorTotal }}
                                            </span>
                                        </div>
                                        <div v-if="!visitorLoading" 
                                                class="text-right ml-2" 
                                                style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ visitorPercentage }}
                                                <span :class="visitorArrow"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 1. End Card -->


                        <!-- 3. Card -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">
                                    <div class="dropdown pull-right show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(inquireDays == '0') ? 'active' : ''"
                                                @click="fetchTotalInquires(0)">{{ $t('dashboard.today') }}</span>
                                            <span class="dropdown-item "
                                                :class="(inquireDays == '1') ? 'active' : ''"
                                                @click="fetchTotalInquires(1)">{{ $t('dashboard.yesterday') }}</span>
                                            <span class="dropdown-item "
                                                :class="(inquireDays == '7') ? 'active' : ''"
                                                @click="fetchTotalInquires(7)">{{ $t('dashboard.last7days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(inquireDays == '28') ? 'active' : ''"
                                                @click="fetchTotalInquires(28)">{{ $t('dashboard.last28days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(inquireDays == '90') ? 'active' : ''"
                                                @click="fetchTotalInquires(90)">{{ $t('dashboard.last90days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(inquireDays == '180') ? 'active' : ''"
                                                @click="fetchTotalInquires(180)">{{ $t('dashboard.last180days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(inquireDays == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalInquires('infinity')">{{ $t('dashboard.lastCalendar') }}</span>
                                        </div>
                                    </div>
                                    <div class="media align-items-center py-2"
                                        :class="(locale == 'ar') ? 'dir-rtl' : ''">
                                        <div class="media-body">
                                            <h5 class="h6 text-muted mb-3" style="font-size:0.9rem">{{ $t('dashboard.total_inbox') }}</h5>
                                            <span v-if="inquireLoading">
                                                <div class="loader loader-sm"></div>
                                            </span>
                                            <span v-if="!inquireLoading" 
                                                class="h4 font-weight-normal mb-0">{{ inquireTotal }}
                                            </span>
                                        </div>
                                        <div v-if="!inquireLoading" 
                                            class="text-right ml-2" 
                                            style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ inquirePercentage }}
                                                <span :class="inquireArrow"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 3. End Card -->

                        <!-- 4. Card -->
                        <!-- <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">
                                    <div class="dropdown pull-right show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(userDays == '0') ? 'active' : ''"
                                                @click="fetchTotalUsers(0)">{{ $t('dashboard.today') }}</span>
                                            <span class="dropdown-item "
                                                :class="(userDays == '1') ? 'active' : ''"
                                                @click="fetchTotalUsers(1)">{{ $t('dashboard.yesterday') }}</span>
                                            <span class="dropdown-item "
                                                :class="(userDays == '7') ? 'active' : ''"
                                                @click="fetchTotalUsers(7)">{{ $t('dashboard.last7days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(userDays == '28') ? 'active' : ''"
                                                @click="fetchTotalUsers(28)">{{ $t('dashboard.last28days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(userDays == '90') ? 'active' : ''"
                                                @click="fetchTotalUsers(90)">{{ $t('dashboard.last90days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(userDays == '180') ? 'active' : ''"
                                                @click="fetchTotalUsers(180)">{{ $t('dashboard.last180days') }}</span>
                                            <span class="dropdown-item "
                                                :class="(userDays == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalUsers('infinity')">{{ $t('dashboard.lastCalendar') }}</span>
                                        </div>
                                    </div>

                                    <div class="media align-items-center py-2"
                                        :class="(locale == 'ar') ? 'dir-rtl' : ''">
                                        <div class="media-body">
                                            <h5 class="h6 text-muted mb-3">{{ $t('dashboard.total_users') }}</h5>
                                            <span v-if="userLoading">
                                                <div class="loader loader-sm"></div>
                                            </span>
                                            <span v-if="!userLoading" 
                                                class="h4 font-weight-normal mb-0">{{ userTotal }}
                                            </span>
                                        </div>
                                        <div v-if="!userLoading" 
                                            class="text-right ml-2" 
                                            style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ userPercentage }}
                                                <span :class="userArrow"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- 4. End Card -->
                        
                    </div>


                    <div class="row" style='margin-top: -40px'
                        :class="(locale == 'ar') ? 'dir-rtl' : ''">

                        <!-- Line Chart -->
                        <div class="col-md-8 mb-5">
                            <div class="card ui-h-480">
                                <header class="card-header d-flex align-items-center justify-content-between"
                                    :class="(locale == 'ar') ? 'dir-rtl' : ''">
                                    <h2 class="h6 card-header-title">{{ $t('dashboard.visitor_during_year') }}</h2>
                                    
                                    <!-- Dropdown -->
                                    <span class="ti-more pull-right cursor-pointer"
                                            id="dropMenuCharts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                     <div class="dropdown-menu" aria-labelledby="dropMenuCharts">
                                        <span class="dropdown-item " 
                                                :class="(lineType == 'weekly') ? 'active' : ''"
                                                @click="fetchLineChart('weekly')">{{ $t('dashboard.weekly') }}
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(lineType == 'monthly') ? 'active' : ''"
                                                @click="fetchLineChart('monthly')">{{ $t('dashboard.monthly') }}
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(lineType == 'quarter') ? 'active' : ''"
                                                @click="fetchLineChart('quarter')">{{ $t('dashboard.quarter') }}
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(lineType == 'yearly') ? 'active' : ''"
                                                @click="fetchLineChart('yearly')">{{ $t('dashboard.yearly') }}
                                        </span>
                                    </div>
                                    <!-- End Dropdown -->
                                </header>

                                <div class="card-body pt-0 text-center">
                                    <span v-if="chartLoading">
                                        <div class="loader loader-sm"></div>
                                    </span>
                                    <apexchart v-if="!chartLoading" 
                                            class="mt-md-3 mt-xl-0"
                                            type=area 
                                            :options="chartOption" 
                                            :series="charts"
                                            style="direction: ltr"  
                                            height="380" />
                                </div>
                            </div>
                        </div>
                        <!-- End Line Chart -->

                        <!-- Pie Chart -->
                        <div class="col-md-4 mb-5">
                            <div class="card ui-h-300">
                                <header class="card-header  
                                    d-flex align-items-center justify-content-between"
                                    :class="(locale == 'ar') ? 'dir-rtl' : ''">
                                    <h2 class="h6 card-header-title">{{ $t('dashboard.where_visitors') }}</h2>

                                    <!-- Dropdown -->
                                    <a class="link-muted d-flex pull-right" 
                                            role="button" 
                                            id="dropMenuCountries" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"
                                            data-toggle="dropdown">
                                            <span class="ti-more pull-right cursor-pointer"></span>
                                   </a>
                                    <div class="u-header-dropdown__menu dropdown-menu dropdown-menu-right" 
                                            aria-labelledby="dropMenuCountries">
                                        <span class="dropdown-item " 
                                                :class="(pieDays == '0') ? 'active' : ''"
                                                @click="fetchPieChart(0)">{{ $t('dashboard.today') }}
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pieDays == '1') ? 'active' : ''"
                                                @click="fetchPieChart(1)">{{ $t('dashboard.yesterday') }}
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pieDays == '7') ? 'active' : ''"
                                                @click="fetchPieChart(7)">{{ $t('dashboard.last7days') }}
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pieDays == '28') ? 'active' : ''"
                                                @click="fetchPieChart(28)">{{ $t('dashboard.last28days') }}
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pieDays == '90') ? 'active' : ''"
                                                @click="fetchPieChart(90)">{{ $t('dashboard.last90days') }}
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pieDays == '180') ? 'active' : ''"
                                                @click="fetchPieChart(180)">{{ $t('dashboard.last180days') }}
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pieDays == 'infinity') ? 'active' : ''"
                                                @click="fetchPieChart('infinity')">{{ $t('dashboard.lastCalendar') }}
                                        </span>
                                    </div>
                                    <!-- End Dropdown -->

                                </header>

                                <div class="card-body text-center">
                                    <span v-if="pieLoading">
                                        <div class="loader loader-sm"></div>    
                                    </span>
                                    <apexchart v-if="!pieLoading" 
                                            class="mt-md-3 mt-xl-0"
                                            type=pie 
                                            :options="pieOptions" 
                                            :series="pies"  
                                            style="direction: ltr"
                                            height="190" />
                                </div>
                            </div>
                        </div>
                        <!-- End Pie Chart -->

                    </div>


                </div>
            </div>


            <Footer></Footer>
        </div>
    </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import iziToast from 'izitoast';

  export default {
        name: 'App',
        components: {
            Header,
            QuickView,
            Footer,
        },
        data(){
            return {
                auth: {
                    role: '',
                    access_token: '',
                },

                // Total Visitors
                visitorDays: 0,
                visitorTotal: 0,
                visitorPercentage: '',
                visitorArrow: '',
                visitorLoading: true,

                // Total Packages
                packageDays: 0,
                packageTotal: 0,
                packagePercentage: '',
                packageArrow: '',
                packageLoading: true,

                // Total Inquires
                inquireDays: 0,
                inquireTotal: 0,
                inquirePercentage: '',
                inquireArrow: '',
                inquireLoading: true,

                // Total User
                userDays: 0,
                userTotal: 0,
                userPercentage: '',
                userArrow: '',
                userLoading: true,

                // Total Amount
                amountDays: 0,
                amountTotal: 0,
                amountPercentage: '',
                amountArrow: '',
                amountLoading: true,

                // line Chart
                charts: [],
                lineType: 'monthly',
                chartLoading: true,
                chartOption: {
                    chart: { height: 450, zoom: { enabled: false }},
                    animations: { enabled: true },
                    dataLabels: {  enabled: false },
                    stroke: {  curve: 'straight' },
                    title: { text: '', align: 'center' },
                    grid: {  row: { colors: ['#f3f3f3', 'transparent'],  opacity: 0.5 }},
                    markers: { size: 5, align:top,  hover: {  sizeOffset: 5  }},
                },

                // Pie
                pieDays: 0,
                pies: [],
                pieLoading: true,
                pieOptions: {
                    chart: {
                      width: 380,
                      type: 'pie',
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                              width: 200
                            },
                        legend: {
                            position: 'bottom'
                        }
                      }
                    }]
                },

                
                locale: 'ar',
            }
        },
        mounted() {},
        created() {

            // AccessToken & Roles
            if(localStorage.getItem('locale')) {
                this.locale = localStorage.getItem('locale');
            }
            if(localStorage.getItem('role')) {
                this.auth.role = localStorage.getItem('role');
            }
            if(localStorage.getItem('access_token')) {
                this.auth.access_token = localStorage.getItem('access_token');
            }
            
            this.fetchTotalPackages(this.packageDays, true);
            this.fetchTotalVisitors(this.visitorDays, true);
            this.fetchTotalAmounts(this.amountDays, true);
            this.fetchTotalInquires(this.inquireDays, true);
            //this.fetchTotalUsers(this.userDays, true);

            this.fetchLineChart(this.lineType, true);
            this.fetchPieChart(this.pieDays, true);

            this.checkPaymentStatus();
        },
        methods: {

            // fetch TotalVisitors
            fetchTotalVisitors(days, next=false){
                this.visitorLoading = true;
                this.visitorDays = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalVisitors/'+this.visitorDays,
                    method: 'GET',
                    data: {},
                    params: {}
                }
                this.axios(options, config)
                    .then(res => {
                        this.visitorLoading    = false;
                        this.visitorTotal      = res.data.total;
                        this.visitorPercentage = res.data.percentage;
                        this.visitorArrow      = res.data.arrow;

                        // Call Next Func
                        //if(next) { this.fetchTotalPackages(this.packageDays, true); }
                        console.log(next)
                    })
                    .catch(err => {
                        if(err.response && err.response.status == 401) {
                          this.removeLocalStorage();
                          this.$router.push({ name: 'login' });
                        }
                        else if(err.response && err.response.status == 403) {
                          this.removeLocalStorage();
                          this.$router.push({ name: 'forbidden' });
                        }
                    });
            },

            // fetch TotalPackages
            fetchTotalPackages(days, next=false){
                this.packageLoading = true;
                this.packageDays = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalDonations/'+this.packageDays,
                    method: 'GET',
                    data: {},
                    params: {}
                }
                this.axios(options, config)
                    .then(res => {
                        this.packageLoading    = false;
                        this.packageTotal      = res.data.total;
                        this.packagePercentage = res.data.percentage;
                        this.packageArrow      = res.data.arrow;

                        // Call Next Func
                        console.log(next);
                       // if(next) { this.fetchTotalInquires(this.inquireDays, true); }
                    })
                    .catch(() => { });
            },

            // fetch TotalAmount
            fetchTotalAmounts(days, next=false){
                this.amountLoading = true;
                this.amountDays = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalAmounts/'+this.amountDays,
                    method: 'GET',
                    data: {},
                    params: {}
                }
                this.axios(options, config)
                    .then(res => {
                        this.amountLoading    = false;
                        this.amountTotal      = res.data.total;
                        this.amountPercentage = res.data.percentage;
                        this.amountArrow      = res.data.arrow;

                        // Call Next Func
                        //if(next) { this.fetchLineChart(this.lineType, true); }
                        console.log(next);
                    })
                    .catch(() => { });
            },

            // fetch TotalInquire
            fetchTotalInquires(days, next=false){
                this.inquireLoading = true;
                this.inquireDays = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalMessages/'+this.inquireDays,
                    method: 'GET',
                    data: {},
                    params: {}
                }
                this.axios(options, config)
                    .then(res => {
                        this.inquireLoading    = false;
                        this.inquireTotal      = res.data.total;
                        this.inquirePercentage = res.data.percentage;
                        this.inquireArrow      = res.data.arrow;

                        // Call Next Func
                        //if(next) { this.fetchTotalUsers(this.userDays, true); }
                        console.log(next);
                     })
                    .catch(() => { });
            },

            // fetch TotalUsers
            fetchTotalUsers(days, next=false){
                this.userLoading = true;
                this.userDays = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalUsers/'+this.userDays,
                    method: 'GET',
                    data: {},
                    params: {}
                }
                this.axios(options, config)
                    .then(res => {
                        this.userLoading    = false;
                        this.userTotal      = res.data.total;
                        this.userPercentage = res.data.percentage;
                        this.userArrow      = res.data.arrow;

                        // Call Next Func
                        //if(next) { this.fetchLineChart(this.lineType, true); }
                        console.log(next);
                    })
                    .catch(() => { });
            },



            // fetch LineChart
            fetchLineChart(type, next=false){
                this.chartLoading = true;
                this.lineType = type;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/lineChart/'+this.lineType,
                    method: 'GET',
                    data: {},
                    params: {}
                }
                this.axios(options, config)
                    .then(res => {
                        this.chartLoading = false;
                        this.charts = [{name:'Views', data: res.data.rows.series}];
                        this.chartOption.xaxis = {categories: res.data.rows.xaxis};

                        console.log(next);
                        // Call Next Func
                        // if(next) { this.fetchPieChart(this.pieDays, true); }
                        // console.log(next);
                           
                    })
                    .catch(() => {});
            },

            // fetch PieChart
            fetchPieChart(days, next=false){
                this.pieLoading = true;
                this.pieDays = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/pieChart/'+this.pieDays,
                    method: 'GET',
                    data: {},
                    params: {}
                }
                this.axios(options, config)
                    .then(res => {
                        this.pieLoading = false;
                        this.pies = res.data.rows.total;
                        this.pieOptions.labels = res.data.rows.countries;

                        // Call Next Func
                        if(next) { 
                            this.fetchRecentPackages(true); 
                        }
                    })
                    .catch(() => { });
            },


            checkPaymentStatus(){
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/recentProjects',
                    method: 'GET',
                    data: {},
                    params: {}
                }
                this.axios(options, config)
                .then(() => { })
                .catch(() => { });
            },



            
                
            checkUpdate(){
                this.updateLoading = true;
                setTimeout(() => {
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: "There's no new updates available!"
                    });
                    this.updateLoading = false;
                },3000);
            },

            // remove sessions
            removeLocalStorage() {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user_image');
                localStorage.removeItem('user_name');
                localStorage.removeItem('user_id');
                localStorage.removeItem('role');
            },

        },
    }
</script>

<style scoped="">
table thead { background-color: #515365 !important }
table tfoot { display: none !important }
.table > thead > tr > th { color: white !important }
</style>