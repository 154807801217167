<template>
  <div class="">
      
    
        <!-- Quick View START -->
        <div class="quick-view">
            <ul class="quick-view-tabs nav nav-tabs nav-justified" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" href="#config" role="tab" data-toggle="tab">
                        <span>{{ $t('projects.settings') }}</span>
                    </a>
                </li>
            </ul>
            
            <div class="tab-content scrollable">
                <div id="config" role="tabpanel" class="tab-pane fade in active">
                    <div class="theme-configurator p-20">
                        <div class="m-v-20 border bottom">
                            <p class="text-dark text-semibold m-b-0">{{ $t('app.headerColor') }}</p>
                            <div class="theme-selector p-b-20">
                                <label>
                                    <input type="radio" 
                                        @click="changeHeaderColor('primary-default')" 
                                        value="default" 
                                        name="header-theme">
                                    <span class="theme-color bg-white border">
                                        <i v-if="headerColor == 'header-default'"
                                             class="fa fa-check" 
                                             style="padding: 5px; color:#000">
                                        </i>
                                    </span>
                                </label>
                                <!-- <label>
                                    <input type="radio" 
                                        @click="changeHeaderColor('header-primary')" 
                                        value="primary" 
                                        name="header-theme">
                                    <span class="theme-color bg-primary">
                                        <i v-if="headerColor == 'header-primary'"
                                             class="fa fa-check" 
                                             style="padding: 5px; color:#fff">
                                        </i>
                                    </span>
                                </label> -->
                                <!-- <label>
                                    <input type="radio" 
                                        @click="changeHeaderColor('header-info')" 
                                        value="info" 
                                        name="header-theme">
                                    <span class="theme-color bg-info">
                                        <i v-if="headerColor == 'header-info'"
                                             class="fa fa-check" 
                                             style="padding: 5px; color:#fff">
                                        </i>
                                    </span>
                                </label> -->
                                <!-- <label>
                                    <input type="radio" 
                                        @click="changeHeaderColor('header-success')" 
                                        value="success" 
                                        name="header-theme">
                                    <span class="theme-color bg-success">
                                        <i v-if="headerColor == 'header-success'"
                                             class="fa fa-check" 
                                             style="padding: 5px; color:#fff">
                                        </i>
                                    </span>
                                </label> -->
                                <!-- <label>
                                    <input type="radio" 
                                        @click="changeHeaderColor('header-warning')" 
                                        value="warning" 
                                        name="header-theme">
                                    <span class="theme-color bg-warning">
                                        <i v-if="headerColor == 'header-warning'"
                                             class="fa fa-check" 
                                             style="padding: 5px; color:#fff">
                                        </i>
                                    </span>
                                </label> -->
                                <!-- <label>
                                    <input type="radio" 
                                        @click="changeHeaderColor('header-danger')" 
                                        value="danger" 
                                        name="header-theme">
                                    <span class="theme-color bg-danger">
                                        <i v-if="headerColor == 'header-danger'"
                                             class="fa fa-check" 
                                             style="padding: 5px; color:#fff">
                                        </i>
                                    </span>
                                </label> -->
                                <label>
                                    <input type="radio" 
                                        @click="changeHeaderColor('header-dark')" 
                                        value="dark" 
                                        name="header-theme">
                                    <span class="theme-color bg-dark">
                                        <i v-if="headerColor == 'header-dark'"
                                             class="fa fa-check" 
                                             style="padding: 5px; color:#fff">
                                        </i>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="m-v-15 border bottom">
                            <p class="text-dark text-semibold m-b-0">{{ $t('app.navColor') }}</p>
                            <!-- <p class="m-b-15">Config side nav background</p> -->
                            <div class="theme-selector p-b-15">
                                <label>
                                    <input type="radio" 
                                            value="default" 
                                            name="side-nav-color"
                                            @click="changeNavColor()">
                                    <span class="theme-color bg-white border">
                                        <i v-if="navColor == 'side-nav-default'"
                                             class="fa fa-check" 
                                             style="padding: 5px; color:#000">
                                        </i>
                                    </span>
                                </label>
                                <label>
                                    <input type="radio" 
                                            value="dark" 
                                            name="side-nav-color"
                                            @click="changeNavColor()">
                                    <span class="theme-color bg-dark">
                                        <i v-if="navColor == 'side-nav-dark'"
                                             class="fa fa-check" 
                                             style="padding: 5px; color:#fff">
                                        </i>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="m-v-15">
                            <p class="text-dark text-semibold m-b-0">{{ $t('app.languages') }}</p>
                            <!-- <p class="m-b-15">Toggle right to left</p> -->
                            <div class="theme-selector p-b-15">
                                <div class="switch checkbox-inline">
                                    <input type="checkbox" 
                                        id="rtl-toogle2" 
                                        @change="changeLocale()">
                                    <label for="rtl-toogle2"></label>
                                </div>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                    <!-- config END -->
                        
                </div>
        </div>
        <!-- Side Panel END -->



  </div>
</template>
<script>
export default {
  name: 'QuickView',
  components: {
    //
  },
  data () {
    return {
      //

      locale: 'ar',
      headerColor: 'header-default',
      navColor: 'side-nav-default',
    }
  },

  created() {

    if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
        this.changeDir(this.locale);
    }
        this.$i18n.locale = this.locale;
        this.$i18n.local = this.locale;

    if(localStorage.getItem('headerColor')) {
        this.headerColor = localStorage.getItem('headerColor');
    }
    if(localStorage.getItem('navColor')) {
        this.navColor = localStorage.getItem('navColor');
    }

  },
  methods: {

            onSearch(){
              this.$emit("headerToChild", this.search);
            },

    // color
    changeLocale() {
        if(this.locale == 'en') {
            // let app = document.querySelectorAll("div.app");
            // let appx = app[app.length-1];

            // appx.classList.remove('ltr');
            // appx.classList.add('rtl');
            //this.locale = 'ar';
            // this.$i18n.locale = 'ar';
            // this.$i18n.local = 'ar';
            localStorage.setItem('locale', 'ar');
            location.reload();
            //this.$emit("headerToChild2", this.locale);
        } else {
            

            // let app = document.querySelectorAll("div.app");
            // let appx = app[app.length-1];
            
            // appx.classList.remove('rtl');
            // appx.classList.add('ltr');
            //this.locale = 'en';
            // this.$i18n.locale = 'en';
            // this.$i18n.local = 'en';
            localStorage.setItem('locale', 'en');
            location.reload();
            //this.$emit("headerToChild2", this.locale);
        }

    },

    changeDir: function(lang) {
        if(lang == 'ar') {
            let body = document.querySelectorAll("div.app");
            let app = body[body.length-1];
            app.classList.remove('ltr');
            app.classList.add('rtl');
        } else {
            let body = document.querySelectorAll("div.app");
            let app = body[body.length-1];
            app.classList.remove('rtl');
            app.classList.add('ltr');
        }
    },

    //
    changeHeaderColor(text) {
        let app = document.querySelectorAll("div.app");
        let appx = app[app.length-1];
        appx.classList.remove('header-default');
        appx.classList.remove('header-primary');
        appx.classList.remove('header-info');
        appx.classList.remove('header-success');
        appx.classList.remove('header-warning');
        appx.classList.remove('header-danger');
        appx.classList.remove('header-dark');
        appx.classList.add(text);
        localStorage.setItem('headerColor', text);
        this.headerColor = text;
    },

    changeNavColor() {
        if(this.navColor == 'side-nav-default') {
            let app = document.querySelectorAll("div.app");
            let appx = app[app.length-1];
            appx.classList.remove('side-nav-default');
            appx.classList.add('side-nav-dark');
            localStorage.setItem('navColor', 'side-nav-dark');
            this.navColor = 'side-nav-dark';
        } else {
            let app = document.querySelectorAll("div.app");
            let appx = app[app.length-1];
            appx.classList.remove('side-nav-dark');
            appx.classList.add('side-nav-default');
            localStorage.setItem('navColor', 'side-nav-default');
            this.navColor = 'side-nav-default';
        }
    },

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>