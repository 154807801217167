<template>
    <div class="">
        
        <Header></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
                <div class="container-fluid">

                    <!-- Header -->
                    <div class="page-header">

                    <!-- Title -->
                    <h2 class="header-title text-capitalize"
                    :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                        <i class="mdi mdi-chart-bar"></i>
                      {{ $t('projects.periodicReports') }}
                    </h2>

                        <router-link :to="{ name: refs }" 
                              class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
                        </router-link>

                        <!-- Role -->
                        <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                            {{ auth.role }}
                        </label>
                        <!-- End Role -->

                        <div class="clearfix"></div>

                        <!-- Breadcrumb -->
                        <nav class="breadcrumb breadcrumb-dash"
                             :class="(locale == 'ar') ? 'dir-rtl' : ''">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="breadcrumb-item">
                                <i class="ti-home p-r-5"></i>
                                {{ $t('projects.dashboard') }}
                            </router-link>
                            <router-link :to="{ name: refs }" 
                                class="breadcrumb-item text-capitalize">{{ $t('projects.periodicReports') }}
                            </router-link>
                            <span class="breadcrumb-item active">{{ $t('projects.add_new') }}</span>
                        </nav>
                        <!-- End Breadcrumb -->
                       
                    </div>  
                    <!-- End Header -->

            <div v-if="pgLoading" class="row h-100">
                <div class="container text-center">
                    <div class="loader loader-lg ui-mtop-15"></div>
                </div>
            </div> 
                        
            <form v-if="!pgLoading" @submit.prevent="addNew" enctype="multipart/form-data"> 
                <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">
                    <div class="col-md-12">

                        <!-- Content -->
                        <div class="card">
                            <div class="row">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-11 ml-auto mr-auto">

                                    
                                    <!-- Languages -->
                                    <div class="tab-danger">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item">
                                                <a href="#tab1" 
                                                    class="nav-link active show" 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="true">{{ $t('projects.data') }}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="#tab2" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('projects.report_data') }}</a>
                                            </li>
                                            <li v-if="row.isMosmy == 0" class="nav-item">
                                                <a href="#tab3" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('projects.donors_data') }}</a>
                                            </li>


                                            <li v-if="row.model_type == 'App\\Models\\Orphan' && 
                                                (auth.role == 'مسؤول-قسم-الأيتام' || auth.role == 'root')"
                                                class="nav-item">
                                                <a href="#tab4" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('app.orphan_approved') }}</a>
                                            </li>
                                            <li v-if="(row.model_type == 'App\\Models\\Postgraduate') && 
                                                (auth.role == 'مسؤول-قسم-الدراسات-العليا' || auth.role == 'root')"
                                                class="nav-item">
                                                <a href="#tab4" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('app.postgraduate_approved') }}</a>
                                            </li>
                                            <li v-if="(row.model_type == 'App\\Models\\Preacher') && 
                                                (auth.role == 'مسؤول-قسم-الدعاه' || auth.role == 'root')"
                                                class="nav-item">
                                                <a href="#tab4" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('app.preacher_approved') }}</a>
                                            </li>
                                            <li v-if="(row.model_type == 'App\\Models\\Project') && 
                                                (auth.role == 'مسؤول-قسم-المشاريع' || auth.role == 'root')"
                                                class="nav-item">
                                                <a href="#tab4" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('app.project_approved') }}</a>
                                            </li>
                                            <li v-if="(row.model_type == 'App\\Models\\Relief') && 
                                                (auth.role == 'مسؤول-قسم-الإغاثات' || auth.role == 'root')"
                                                class="nav-item">
                                                <a href="#tab4" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('app.relief_approved') }}</a>
                                            </li>
                                            <li v-if="(row.model_type == 'App\\Models\\Endowment') && 
                                                (auth.role == 'مسؤول-قسم-الوقفيات' || auth.role == 'root')"
                                                class="nav-item">
                                                <a href="#tab4" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('app.endowment_approved') }}</a>
                                            </li>
                                        </ul>

                                        <div class="tab-content">
                                            <div role="tabpanel" 
                                                class="tab-pane fade active show" 
                                                id="tab1">

                                                <div class="p-v-20">

                                                    <div class="form-group col-6">
                                                        <label class="control-label">
                                                            {{ $t('projects.choose_cat') }}
                                                        </label>
                                                        <select 
                                                            class="form-control" 
                                                            v-model="row.model_type"
                                                            @change="fetchElements()">
                                                            <option value="">
                                                                {{ $t('projects.choose_cat') }}
                                                            </option>
                                                            <option value="orphans">
                                                                {{ $t('nav.orphans') }}
                                                            </option>
                                                            <option value="preachers">
                                                                {{ $t('nav.preachers') }}
                                                            </option>
                                                            <option value="postgraduates">
                                                                {{ $t('nav.postgraduates') }}
                                                            </option>
                                                            <option value="projects">
                                                                {{ $t('nav.projects') }}
                                                            </option>
                                                            <option value="reliefs">
                                                                {{ $t('nav.reliefs') }}
                                                            </option>
                                                            <option value="endowments">
                                                                {{ $t('nav.endowments') }}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div v-if="row.model_type" class="form-group col-6">
                                                        <label class="control-label">
                                                            {{ $t('projects.choose_element') }}
                                                        </label>
                                                        <div v-if="elementLoading" class="text-center">
                                                            <div class="loader loader-xs"></div>
                                                        </div>
                                                        <select 
                                                            v-if="!elementLoading" 
                                                            class="form-control" 
                                                            v-model="row.model_id"
                                                            @change="fetchRow()">
                                                            <option 
                                                                v-for="(el, idx) in elements"
                                                                :key="idx" 
                                                                :value="el.id">
                                                                {{ (el.title) ? el.title : el.name }}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div v-if="fetchLoading" class="text-center col-6">
                                                        <div class="loader loader-xs"></div>
                                                    </div>
                                                    <div v-if="!fetchLoading && fetch" 
                                                        class="form-group col-6">
                                                        <div class="form-group">
                                                            <img v-if="fetch.image"
                                                                :src="(fetch.image) ? fetch.image.url : ''" 
                                                                style="width: 70%;max-height: 300px">
                                                        </div>

                                                        <div v-if="
                                                            row.model_type == 'orphans' ||
                                                            row.model_type == 'preachers' ||
                                                            row.model_type == 'postgraduates'">
                                                            <div v-if="fetch.name">
                                                                <h5>{{ $t('projects.orp_name') }}</h5>
                                                                <p>{{ fetch.name }}</p>
                                                            </div>

                                                            <div v-if="fetch.comb_number">
                                                                <h5>{{ $t('projects.theNumberId') }}</h5>
                                                                <p>{{ fetch.comb_number }}</p>
                                                            </div>

                                                            <div v-if="fetch.mother_name">
                                                                <h5>{{ $t('projects.mother_name') }}</h5>
                                                                <p>{{ fetch.mother_name }}</p>
                                                            </div>

                                                            <div v-if="fetch.geneder_name">
                                                                <h5>{{ $t('projects.geneder') }}</h5>
                                                                <p>
                                                                {{ 
                                                                    (fetch.geneder_name == 'Female') 
                                                                        ? 'انثي' : 'ذكر'  }}
                                                                </p>
                                                            </div>

                                                            <div v-if="fetch.birth_date">
                                                                <h5>{{ $t('projects.birth_date') }}</h5>
                                                                <p> {{ fetch.birth_date }}</p>
                                                            </div>

                                                            <div v-if="fetch.birth_place">
                                                                <h5>{{ $t('projects.birth_place') }}</h5>
                                                                <p> {{ fetch.birth_place }}</p>
                                                            </div>

                                                        </div>

                                                        <div v-if="
                                                            row.model_type == 'projects' || 
                                                            row.model_type == 'reliefs' ||
                                                            row.model_type == 'endowments'">
                                                            <div v-if="fetch.title">
                                                                <h5>{{ $t('projects.pro_name') }}</h5>
                                                                <p>{{ fetch.ar.title }}</p>
                                                            </div>

                                                            <div v-if="fetch.comb_number">
                                                                <h5>{{ $t('projects.theNumberId') }}</h5>
                                                                <p>{{ fetch.comb_number }}</p>
                                                            </div>

                                                            <div v-if="fetch.body">
                                                                <h5>{{ $t('projects.pro_desc') }}</h5>
                                                                <p v-html="fetch.ar.body"></p>
                                                            </div>


                                                            <div v-if="fetch.country">
                                                                <h5>{{ $t('projects.country') }} - {{ $t('projects.city') }}</h5>
                                                                <p> 
                                                                    {{ (fetch.country) ? fetch.country.translations[1].name : '' }} - 
                                                                    {{ (fetch.city) ? fetch.city.translations[1].name : '' }}
                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab2">
                                                
                                                <div class=" p-v-20">
                                                    <div class="form-group">
                                                        <label v-if="
                                                            row.model_type == '' ||
                                                            row.model_type == 'orphans' || 
                                                            row.model_type == 'projects' || 
                                                            row.model_type == 'reliefs' ||
                                                            row.model_type == 'endowments'"
                                                        >{{ $t('projects.report_txt1') }}</label>
                                                        <label v-if="
                                                            row.model_type == 'preachers' ||
                                                            row.model_type == 'postgraduates'">
                                                            {{ $t('projects.report_txt1_2') }}</label>
                                                        <input type="text" class="form-control" v-model="row.title">
                                                    </div>

                                                    <div v-if="
                                                        row.model_type == 'projects' || 
                                                        row.model_type == 'reliefs' ||
                                                        row.model_type == 'endowments'"
                                                        class="form-group">
                                                        <label>{{ $t('projects.report_txt9') }}</label>
                                                        <input type="text" class="form-control" v-model="row.content">
                                                    </div>

                                                    <div v-if="
                                                            row.model_type == 'orphans' || 
                                                            row.model_type == 'postgraduates'" 
                                                            class="form-group">
                                                        <label v-if="row.model_type == 'orphans'">
                                                            {{ $t('projects.report_txt2') }}</label>
                                                        <label v-if="row.model_type == 'postgraduates'">
                                                            {{ $t('projects.report_txt2_2') }}</label>
                                                        <input type="text" class="form-control" v-model="row.education_status">
                                                    </div>
                                                    <div v-if="row.model_type == 'orphans'" class="form-group">
                                                        <label>{{ $t('projects.report_txt3') }}</label>
                                                        <input type="text" class="form-control" v-model="row.health_status">
                                                    </div>
                                                    <div v-if="
                                                        row.model_type == 'orphans' || 
                                                        row.model_type == 'preachers' ||
                                                        row.model_type == 'postgraduates'" 
                                                        class="form-group">
                                                        <label>{{ $t('projects.report_txt4') }}</label>
                                                        <input type="text" class="form-control" v-model="row.needs">
                                                    </div>
                                                    <div v-if="
                                                        row.model_type == 'preachers' || 
                                                        row.model_type == 'postgraduates'" 
                                                        class="form-group">
                                                        <label v-if="row.model_type == 'preachers'">{{ $t('projects.report_txt8') }}</label>
                                                        <label v-if="row.model_type == 'postgraduates'">{{ $t('projects.report_txt8_2') }}</label>
                                                        <input type="text" class="form-control" v-model="row.activity">
                                                    </div>
                                                    <div v-if="row.model_type == 'orphans'" class="form-group">
                                                        <label>{{ $t('projects.report_txt5') }}</label>
                                                        <input type="text" class="form-control" v-model="row.quran_saves">
                                                    </div>
                                                    <div v-if="row.model_type == 'orphans'" class="form-group">
                                                        <label>{{ $t('projects.report_txt6') }}</label>
                                                        <input type="text" class="form-control" v-model="row.address">
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <label>{{ $t('projects.report_txt7') }}</label>
                                                        <input type="text" class="form-control" v-model="row.notes">
                                                    </div>

                                                    <div v-if="
                                                        row.model_type == 'projects' ||
                                                        row.model_type == 'reliefs' ||
                                                        row.model_type == 'endowments'" 
                                                        class="form-group">
                                                        <input type="checkbox" 
                                                            v-model="row.isMosmy" 
                                                            @click="checkMosmy"
                                                            :checked="(row.isMosmy == 1) ? true : false">
                                                        &nbsp;<label>&nbsp; {{ $t('projects.isMosmy') }} &nbsp;</label>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label>{{ $t('projects.mr2yat') }}</label>
                                                        <div class="col-12"><br/></div>
                                                        
                                                     <div v-for="i in row.gallery_key" 
                                                        :key="i" 
                                                        :class="(i == 0) ? 'hidden' : ''"
                                                        class="col-12"
                                                        :id="'frm_'+i">
                                                        <div class="col-2">
                                                            <div v-if="i != 0" class="form-group">
                                                                <button type="button"
                                                                    class="btn btn-danger btn-rounded btn-sm btn-bold ui-mt30"
                                                                    @click="removeOption(i)">
                                                                    <span class="icon-holder">
                                                                        <i class="mdi mdi-delete"></i> حذف الصورة
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 form-group">
                                                            <label class="control-label">
                                                                {{ $t('projects.upload_image') }}
                                                            </label>
                                                            <p>
                                                            <img v-if="row.gallery_preview[i]" 
                                                                :src="row.gallery_preview[i]"
                                                                style="max-width: 100%">
                                                            </p>
                                                            <input 
                                                                class="form-control" 
                                                                type="file"
                                                                accept="image/*"
                                                                v-on:change="onGalleryImageChange($event, i)">
                                                            <!-- <textarea 
                                                                class="form-control" 
                                                                v-model="row.gallery_title[i]">
                                                            </textarea> -->
                                                        </div>
                                                        <br/><hr><br/>
                                                        
                                                    </div>
                                                    
                                                    <div class="col-12">
                                                        <br/><hr><br/>
                                                    </div>
                                                    <div class="col-12">
                                                        <button type="button" 
                                                            class="btn btn-primary btn-rounded btn-bold pull-right"
                                                            @click="addMore()">
                                                            <span class="icon-holder">
                                                                <i class="mdi mdi-plus-circle"></i>
                                                                {{ $t('app.add_new') }}
                                                            </span>
                                                        </button>
                                                    </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab3">
                                                
                                                <div class=" p-v-20">
                                                    <div class="form-group col-6">
                                                        <label class="control-label">
                                                            {{ $t('projects.pgVolunteers') }}
                                                        </label>
                                                        <div v-if="volunteersLoading" class="text-center">
                                                            <div class="loader loader-xs"></div>
                                                        </div>
                                                        <multiselect v-if="!volunteersLoading"
                                                            id="multiselect"
                                                            ref="multiselectRef"
                                                            autocomplete="on"
                                                            v-model="row.volunteersValues" 
                                                            :options="volunteersOptions" 
                                                            :multiple="true"
                                                            :close-on-select="false" 
                                                            :clear-on-select="false" 
                                                            :hide-selected="true" 
                                                            :preserve-search="true" 
                                                            :taggable="false"
                                                            :placeholder="$t('projects.choose_volunteers')"
                                                            :preselect-first="false">
                                                        </multiselect>
                                                    </div>
                                                </div>
                                            </div>

                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab4">
                                                
                                                <div class=" p-v-20">
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <!-- End Languages -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Content -->


                        <!-- Button -->
                        <div class="form-group">
                            <button class="btn btn-dark btn-size" :disabled="btnLoading">
                                <span v-if="btnLoading">
                                    <div class="loader loader-xs loader-center"></div>
                                </span>
                                <span v-if="!btnLoading">{{ $t('projects.create') }}</span>
                            </button>
                            <button type="button" @click="cancel" :disabled="btnLoading"
                                class="btn btn-default btn-size p-10">
                                {{ $t('projects.cancel') }}
                            </button>
                        </div>  
                        <!-- End Buttons -->

                    </div>
                </div>
            </form>


                </div>
            </div>

        <Footer></Footer>
        </div>

    </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import iziToast from 'izitoast';
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'Create',
    components: {
      Header,
      QuickView,
      Footer,
      Multiselect
    },
    mounted() {},
    data() {
      return {
        //
        auth: {
            access_token: '',
            role: '',
        },
        row: {
            // row
            title: '',
            content: '',
            education_status: '',
            health_status: '',
            needs: '',
            quran_saves: '',
            address: '',
            activity: '',
            notes: '',
            
            model_id: '',
            model_type: '',
            reject_reason: '',
            isMosmy: 0,
            has_volunteers: 0,
            volunteersValues: [],
            status: 0,


            // Galleries
            galleries: [],
            gallery_key: 0,
            gallery_preview: [],
            gallery_image: [],
            gallery_title: [],
        },
        editor: {
            api_key: window.editor_apiKey,
            menubar: window.editor_menubar,
            plugins:[window.editor_plugins],
            toolbar: window.editor_toolbar,
        },

        elementLoading: false,
        elements: [],

        fetchLoading: false,
        fetch: [],

        volunteersLoading: true,
        volunteersOptions: [],

        pgLoading: false,
        btnLoading: false,
        isClicked: true,
        locale: 'ar',
        refs: 'periodicReports',
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if(localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if(localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      }

      this.fetchVolunteers();
      //
    },
    methods: {

        checkMosmy() {
            if(this.row.isMosmy == 0) {
                this.row.isMosmy = 1;
            } else {
                this.row.isMosmy = 0;
            }
        },

        fetchElements() {
            this.elementLoading = true;
            this.fetch = '';
            this.model_id = '';
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            var side = '';
            if(this.row.model_type == 'orphans') {
                side = 'orphans';
            } else if(this.row.model_type == 'preachers') {
                side = 'preachers';
            } else if(this.row.model_type == 'postgraduates') {
                side = 'postgraduates';
            } else if(this.row.model_type == 'projects') {
                side = 'projects';
            } else if(this.row.model_type == 'reliefs') {
                side = 'reliefs';
            } else if(this.row.model_type == 'endowments') {
                side = 'endowments';
            }
            const options = {
                url: window.baseURL+'/periodicReports/fetch/'+side,
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                }
            }
            this.$axios(options)
            .then(res => {
                this.elementLoading = false;
                this.elements = res.data.rows;
            })
            .catch(() => {})
            .finally(() => {});
        },

        addMore() {
            this.row.gallery_key++;
        },
        removeOption(i) {
            document.querySelector('#frm_'+i).remove();
            this.row.gallery_image[i] = '';
        },
        onGalleryImageChange(e, i){
            const file = e.target.files[0];
            this.row.gallery_preview[i] = URL.createObjectURL(file);
            this.createBase64Gallery(file, i);
        },
        createBase64Gallery(fileObject, i){
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);
            reader.onload = e =>{
                this.row.gallery_image[i] = e.target.result;
            };
        },

        fetchRow() {
            this.fetchLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            var side = '';
            if(this.row.model_type == 'orphans') {
                side = 'orphans';
            } else if(this.row.model_type == 'preachers') {
                side = 'preachers';
            } else if(this.row.model_type == 'postgraduates') {
                side = 'postgraduates';
            } else if(this.row.model_type == 'projects') {
                side = 'projects';
            } else if(this.row.model_type == 'reliefs') {
                side = 'reliefs';
            } else if(this.row.model_type == 'endowments') {
                side = 'endowments';
            }
            const options = {
                url: window.baseURL+'/'+side+'/'+this.row.model_id,
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                }
            }
            this.$axios(options)
            .then(res => {
                this.fetchLoading = false;
                this.fetch = res.data.row;
            })
            .catch(() => {})
            .finally(() => {});
        },

        fetchVolunteers() {
            this.volunteersLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/volunteers',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: "active",
                    paginate: 100
                }
            }
            this.$axios(options)
            .then(res => {
                this.volunteersLoading = false;
                this.volunteersOptions = res.data.data;
            })
            .catch(() => {})
            .finally(() => {});
        },

        // add New
        addNew() {
            this.isClicked = false; // disabled alert msg
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            // galleries
            for( let i = 1; i <= this.row.gallery_key; i++ ) {
                this.row.galleries[i] = {
                    'gallery_image' : this.row.gallery_image[i],
                    'gallery_title' : this.row.gallery_title[i]
                }
            }

            const options = {
                url: window.baseURL+'/'+this.refs,
                method: 'POST',
                data: {
                    // row
                    en: {
                        title: this.row.title,
                        education_status: this.row.education_status,
                        health_status: this.row.health_status,
                        needs: this.row.needs,
                        quran_saves: this.row.quran_saves,
                        address: this.row.address,
                        content: this.row.content,
                        activity: this.row.activity,
                        notes: this.row.notes
                    },
                    ar: {
                        title: this.row.title,
                        education_status: this.row.education_status,
                        health_status: this.row.health_status,
                        needs: this.row.needs,
                        quran_saves: this.row.quran_saves,
                        address: this.row.address,
                        content: this.row.content,
                        activity: this.row.activity,
                        notes: this.row.notes
                    },
                    
                    galleries: this.row.galleries,

                    model_id: this.row.model_id,
                    model_type: this.row.model_type,

                    isMosmy: this.row.isMosmy,
                    volunteers: this.row.volunteersValues,
                    has_volunteers: this.row.has_volunteers,
                    reject_reason: this.row.reject_reason,
                    status: this.row.status
                }
            }
        
            this.$axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: '',
                    message: 'تم الإضافة بنجاح',
                });
                this.$router.push({ name: this.refs });
                this.isClicked = true; // enabled alert msg
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });

                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
                this.isClicked = true; // enabled alert msg
            })
            .finally(() => { })
        },

        // remove LocalStorage
        removeLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('avatar');
            localStorage.removeItem('role');
        },

        cancel() {
            if(confirm('هل تريد المغادرة ؟')) {
                this.$router.push({ name: this.refs })
            }
        },
    },

        beforeRouteLeave(to, from, next) {
            if(this.isClicked) {
                if(confirm('هل تريد المغادرة ؟')) {
                    next();
                }
            }
        },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>