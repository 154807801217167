<template>
    <div class="">
        
        <Header></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
                <div class="container-fluid">

                    <!-- Header -->
                    <div class="page-header">

                        <!-- Title -->
                        <h2 class="header-title text-capitalize"
                          :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                          <i class="mdi mdi-image-filter"></i>
                          {{ $t('projects.gallery') }}
                      </h2>
                        <router-link :to="{ name: refs }" 
                              class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
                        </router-link>

                        <!-- Role -->
                        <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                            {{ auth.role }}
                        </label>
                        <!-- End Role -->

                        <div class="clearfix"></div>

                        <!-- Breadcrumb -->
                        <nav class="breadcrumb breadcrumb-dash"
                            :class="(locale == 'ar') ? 'dir-rtl' : ''">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="breadcrumb-item">
                                <i class="ti-home p-r-5"></i>
                                {{ $t('projects.dashboard') }}
                            </router-link>
                            <router-link :to="{ name: refs }" 
                                class="breadcrumb-item text-capitalize">{{ $t('projects.gallery') }}
                            </router-link>
                            <span class="breadcrumb-item active">{{ $t('projects.add_new') }}</span>
                        </nav>
                        <!-- End Breadcrumb -->
                       
                    </div>  
                    <!-- End Header -->

            <div v-if="pgLoading" class="row h-100">
                <div class="container text-center">
                    <div class="loader loader-lg ui-mtop-15"></div>
                </div>
            </div> 
                        
            <form v-if="!pgLoading" @submit.prevent="addNew" enctype="multipart/form-data"> 
                <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">

                    <div class="col-md-8">

                        <!-- Content -->
                        <div class="card">
                            <div class="row">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-11 ml-auto mr-auto">

                                    
                                    <!-- Languages -->
                                    <div class="tab-danger">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item">
                                                <a href="#tab-arabic" 
                                                    class="nav-link  active show" 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="true">{{ $t('projects.arabic') }}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="#tab-english" 
                                                    class="nav-link" 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('projects.english') }}</a>
                                            </li>
                                            
                                        </ul>

                                        <div class="tab-content">
                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab-english">

                                                <div class="p-v-20">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.title') }}</label>
                                                        <input type="text"
                                                            class="form-control" 
                                                            v-model="row.title_en">
                                                    </div>
                                                </div>
                                            </div>

                                            <div role="tabpanel" 
                                                class="tab-pane fade active show" 
                                                id="tab-arabic">
                                                
                                                <div class=" p-v-20">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.title') }}</label>
                                                        <input type="text"
                                                            class="form-control" 
                                                            v-model="row.title_ar">
                                                    </div>
                                                </div>

                                            </div>
                                            
                                        </div>
                                    </div>
                                    <!-- End Languages -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Content -->


                        <!-- Button -->
                        <div class="form-group">
                            <button class="btn btn-dark btn-size" :disabled="btnLoading">
                                <span v-if="btnLoading">
                                    <div class="loader loader-xs loader-center"></div>
                                </span>
                                <span v-if="!btnLoading">{{ $t('projects.create') }}</span>
                            </button>
                            <button type="button" @click="cancel" :disabled="btnLoading"
                                class="btn btn-default btn-size p-10">
                                {{ $t('projects.cancel') }}
                            </button>
                        </div>  
                        <!-- End Buttons -->

                    </div>

                    <div class="col-md-4">

                        <!-- Image -->
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="control-label">{{ $t('projects.image') }}
                                        <button v-if="row.preview"
                                            type="button" 
                                            class="btn btn-danger btn-sm btn-rounded btn-bold"
                                            style="margin-top: 5px" 
                                            @click="row.preview = ''; row.image=''">
                                            {{ $t('projects.remove') }}
                                        </button>
                                    </label>
                                    <p v-if="row.preview">
                                        <img :src="row.preview" 
                                        style="max-width: 100%;height: 200px">
                                    </p>
                                    <input type="file" 
                                        class="form-control border-0 p-l-0"
                                        ref="myDropify"
                                        accept="image/*" 
                                        v-on:change="onImageChange">
                                </div>
                            </div>
                        </div>
                        <!-- End Image -->

                        <!-- Sort -->
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('projects.sort') }}</label>
                                        <input class="form-control" 
                                                type="number"
                                                min="0" 
                                                v-model.number="row.sort">
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <!-- End Sort -->

                        <!-- Status -->
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <div class="m-b-10">  
                                        <label class="control-label">{{ $t('projects.status') }}</label>
                                    </div>   
                                    <div class="radio d-inline m-r-15">
                                        <input id="statusRadio1" 
                                            type="radio"
                                            v-model="row.status"
                                            value="1" 
                                            :checked="row.status">
                                        <label for="statusRadio1"> {{ $t('projects.active') }} </label>
                                    </div>
                                    <div class="radio d-inline m-r-15">
                                        <input id="statusRadio2" 
                                            type="radio"
                                            v-model="row.status"
                                            value="0"
                                            :checked="row.status">
                                        <label for="statusRadio2"> {{ $t('projects.inactive') }} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Status -->

                    </div>

                </div>
            </form>


                </div>
            </div>

        <Footer></Footer>
        </div>

    </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import iziToast from 'izitoast';

  export default {
    name: 'Create',
    components: {
      Header,
      QuickView,
      Footer,
    },
    mounted() {},
    data() {
      return {
        //
        auth: {
            access_token: '',
            role: '',
        },
        row: {
            // row
            title_en: '',
            title_ar: '',

            // image
            preview: '',
            image: '',

            // status & visiblity
            sort: 0,
            status: 1
        },
        editor: {
            api_key: window.editor_apiKey,
            menubar: window.editor_menubar,
            plugins:[window.editor_plugins],
            toolbar: window.editor_toolbar,
        },

        pgLoading: false,
        btnLoading: false,
        isClicked: true,
        locale: 'ar',
        refs: 'gallery',
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if(localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if(localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      }

      //
    },
    methods: {

        // add New
        addNew() {
            this.isClicked = false; // disabled alert msg
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/'+this.refs,
                method: 'POST',
                data: {
                    // row
                    en: {
                        title: this.row.title_en,
                    },
                    ar: {
                        title: this.row.title_ar,
                    },
                    
                    // image
                    image: this.row.image,

                    // status & visibility
                    sort: this.row.sort,
                    status: this.row.status
                }
            }
        
            this.$axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: '',
                    message: 'تم الإضافة بنجاح',
                });
                this.$router.push({ name: this.refs });
                this.isClicked = true; // enabled alert msg
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });

                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
                this.isClicked = true; // enabled alert msg
            })
            .finally(() => { })
        },

        // remove LocalStorage
        removeLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('avatar');
            localStorage.removeItem('role');
        },

        // Upload image
        onImageChange(e){
            const file = e.target.files[0];
            this.row.preview = URL.createObjectURL(file);
            this.createBase64Image(file);
        },
        createBase64Image(fileObject){
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);
            reader.onload = e =>{
                this.row.image = e.target.result;
            };
        },


        cancel() {
            if(confirm('هل تريد المغادرة ؟')) {
                this.isClicked=false;
                this.$router.push({ name: this.refs })
            }
        },
    },

        beforeRouteLeave(to, from, next) {
            if(this.isClicked) {
                if(confirm('هل تريد المغادرة ؟')) {
                    next();
                }
            } else {
                next();
            }
        },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>