<template>
    <div class="">
        
      <Header></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
                <div class="container-fluid">

                    <!-- Header -->
                    <div class="page-header">

                        <!-- Title -->
                        <h2 class="header-title text-capitalize"
                            :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                      <i class="mdi mdi-settings"></i>
                      {{ $t('projects.settings') }}
                  </h2>
                        <router-link :to="{ name: refs }" 
                              class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
                        </router-link>

                        <!-- Role -->
                        <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                            {{ auth.role }}
                        </label>
                        <!-- End Role -->

                        <div class="clearfix"></div>

                        <!-- Breadcrumb -->
                        <nav class="breadcrumb breadcrumb-dash"
                            :class="(locale == 'ar') ? 'dir-rtl' : ''">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="breadcrumb-item">
                                <i class="ti-home p-r-5"></i>
                                {{ $t('projects.dashboard') }}
                            </router-link>
                            <span class="breadcrumb-item text-capitalize active">{{ $t('projects.settings') }}</span>
                        </nav>
                        <!-- End Breadcrumb -->
                       
                    </div>  
                    <!-- End Header -->

                <div v-if="pgLoading" class="row h-100">
                    <div class="container text-center">
                        <div class="loader loader-lg ui-mtop-15"></div>
                    </div>
                </div> 

                 <div v-if="!pgLoading" class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">


            

                

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.achievements') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'achievements'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.contact_us') }} </h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'contact-us'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.donateNow') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'donateNow'}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.urgent') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'urgent'}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.login') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'login'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.register') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'register'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.forget_password') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'forget-password'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.reset_password') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'reset-password'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.profile') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'profile'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.about_us') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'about-us'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.contacts_us') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'contacts'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.our_services') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'our-services'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card text-center">
                            <div class="card-header">
                                <h4>{{ $t('projects.general_breif') }}</h4>
                            </div>
                            <div class="ui-mb10">
                                <router-link
                                    :to="{ name: 'show-settings', params:{slug: 'general-brief'}}"
                                    class="btn btn-rounded btn-bold text-uppercase btn-success">
                                    <span>{{ $t('projects.update') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>




                  </div>

                </div>
            </div>

          <Footer></Footer>
        </div>

  </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';

  export default {
    name: 'Settings',
    components: {
      Header,
      QuickView,
      Footer,
    },
    mounted() {},
    data() {
      return {
        //
        auth: {
            access_token: '',
            role: '',
        },

        pgLoading: false,
        btnLoading: false,
        locale: 'ar',
        refs: 'settings',
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if(localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if(localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      }

      //
    },
    methods: {
        //
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>