<template>
    <div class="">

      <Header v-on:headerToChild="onSearchSubmit"></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
              <div class="container-fluid">

                <!-- Header -->
                <div class="page-header">
                  
                  <h2 class="header-title text-capitalize"
                      :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                      <i class="mdi mdi-chart-line"></i>
                      {{ $t('projects.reports') }}
                  </h2>


                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle dir-rtl"
                      :class="(locale == 'ar') ? 'dir-rtl' : ''" 
                      type="button" 
                      id="dropdownMenuButton" 
                      data-toggle="dropdown"
                      aria-haspopup="true" 
                      aria-expanded="false">
                      <span v-if="type == 'orphans'">{{ $t('reports.orphans') }}</span>
                      <span v-if="type == 'orphans_donations'">{{ $t('reports.orphans_donations') }}</span>

                      <!-- <span v-if="type == 'preachers'">{{ $t('reports.preachers') }}</span>
                      <span v-if="type == 'preachers_donations'">{{ $t('reports.preachers_donations') }}</span>

                      <span v-if="type == 'postgraduates'">{{ $t('reports.postgradutes') }}</span>
                      <span v-if="type == 'postgraduates_donations'">{{ $t('reports.postgradutes_donations') }}</span> -->

                      <span v-if="type == 'projects'">{{ $t('reports.projects') }}</span>
                      <span v-if="type == 'projects_donations'">{{ $t('reports.projects_donations') }}</span>


                      <!-- <span v-if="type == 'reliefs'">{{ $t('reports.reliefs') }}</span>
                      <span v-if="type == 'reliefs_donations'">{{ $t('reports.reliefs_donations') }}</span> -->

                      <span v-if="type == 'endowments'">{{ $t('reports.endowments') }}</span>
                      <span v-if="type == 'endowments_donations'">{{ $t('reports.endowments_donations') }}</span>

                      <span v-if="type == 'share_links'">{{ $t('app.share_links') }}</span>
                      <span v-if="type == 'general_donations'">{{ $t('projects.general_donation') }}</span>
                      <span v-if="type == 'all_donations'">{{ $t('projects.all_donations') }}</span>

                      <span v-if="type == 'combination_donation'">{{ $t('projects.combination_donation') }}</span>
                    </button>
                    <div class="dropdown-menu z" aria-labelledby="dropdownMenuButton"
                      :class="(locale == 'ar') ? 'text-right' : ''">

                      <router-link :to="'/reports/type/orphans'" 
                            class="dropdown-item">{{ $t('reports.orphans') }}
                      </router-link>
                      <router-link :to="'/reports/donation/orphans_donations'" 
                            class="dropdown-item">{{ $t('reports.orphans_donations') }}
                      </router-link>
                      <div class="dropdown-divider"></div>

                      
                      <!-- <div class="dropdown-divider"></div>
                      <router-link :to="'/reports/type/preachers'" 
                            class="dropdown-item">{{ $t('reports.preachers') }}
                      </router-link>
                      <router-link :to="'/reports/donation/preachers_donations'" 
                            class="dropdown-item">{{ $t('reports.preachers_donations') }}
                      </router-link> -->


                      <!-- <div class="dropdown-divider"></div>
                      <router-link :to="'/reports/type/postgradutes'" 
                            class="dropdown-item">{{ $t('reports.postgradutes') }}
                      </router-link>
                      <router-link :to="'/reports/donation/postgradutes_donations'" 
                            class="dropdown-item">{{ $t('reports.postgradutes_donations') }}
                      </router-link>
                      <div class="dropdown-divider"></div> -->

                      <router-link :to="'/reports/type/projects'" 
                            class="dropdown-item">{{ $t('reports.projects') }}
                      </router-link>
                      <router-link :to="'/reports/donation/projects_donations'" 
                            class="dropdown-item">{{ $t('reports.projects_donations') }}
                      </router-link>
                      <div class="dropdown-divider"></div>
                     
                      <!-- <router-link :to="'/reports/type/reliefs'" 
                            class="dropdown-item">{{ $t('reports.reliefs') }}
                      </router-link>
                      <router-link :to="'/reports/donation/reliefs_donations'" 
                            class="dropdown-item">{{ $t('reports.reliefs_donations') }}
                      </router-link> -->
                      <div class="dropdown-divider"></div>
                      <router-link :to="'/reports/type/endowments'" 
                            class="dropdown-item">{{ $t('reports.endowments') }}
                      </router-link>
                      <router-link :to="'/reports/donation/endowments_donations'" 
                            class="dropdown-item">{{ $t('reports.endowments_donations') }}
                      </router-link>

                      <div class="dropdown-divider"></div>
                      <router-link :to="'/reports/share/share_links'" 
                            class="dropdown-item">{{ $t('app.share_links') }}
                      </router-link>

                      <div class="dropdown-divider"></div>
                      <router-link :to="'/reports/general/donations'" 
                            class="dropdown-item">{{ $t('projects.general_donation') }}
                      </router-link>

                      <div class="dropdown-divider"></div>
                      <router-link :to="'/reports/all/donations'" 
                            class="dropdown-item">{{ $t('projects.all_donations') }}
                      </router-link>

                      <div class="dropdown-divider"></div>
                      <router-link :to="'/reports/groupByDonations'" 
                            class="dropdown-item">{{ $t('projects.combination_donation') }}
                      </router-link>

                    </div>
                  </div>

                  <!-- Role -->
                  <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                    {{ auth.role }}
                  </label>
                  <!-- End Role -->

                  <div class="clearfix"></div>
                  
                  <div class="pull-right hidden-sm">
                    <div class="dropdown">


                      <!-- Export -->
                      <div class="dropdown">
                        <button type="button" 
                            class="btn btn-secondary btn-sm dropdown-toggle btn-size-sm" 
                            id="dropdownMenuButton"
                            data-toggle="dropdown" 
                            aria-haspopup="true" 
                            aria-expanded="false" 
                            :disabled="exportLoading">
                          <span v-if="!exportLoading">{{ $t('app.export') }}</span>
                          <span v-if="exportLoading">
                            <div class="loader loader-sm"></div>
                          </span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <download-excel
                            class = "dropdown-item cursor-pointer"
                            :fetch = "fetchExport"
                            :fields = "exp.json_fields"
                            :before-generate = "startDownload"
                            :before-finish = "finishDownload"
                            :worksheet = "refs"
                            :name = "fileName+'.xls'">{{ $t('app.excel') }}
                          </download-excel>
                          <download-excel
                            class = "dropdown-item cursor-pointer"
                            :fetch = "fetchExport"
                            :fields = "exp.json_fields"
                            :before-generate = "startDownload"
                            :before-finish = "finishDownload"
                            type = "csv"
                            :worksheet = "refs"
                            :name = "fileName+'.xls'">{{ $t('app.csv') }}
                          </download-excel>
                          <a class="dropdown-item" 
                              href="javascript:;" 
                              v-print="'#printMe'">{{ $t('app.print') }}
                          </a>
                        </div>
                      </div>
                      <!-- End Export -->
                      
                    </div>
                  </div>
                        
                  <!-- Breadcrumb -->
                  <nav class="breadcrumb breadcrumb-dash"
                    :class="(locale == 'ar') ? 'dir-rtl' : ''">
                    <router-link :to="{ name: 'dashboard' }" 
                          class="breadcrumb-item">
                          <i class="ti-home p-r-5"></i>
                          {{ $t('projects.dashboard') }}
                    </router-link>
                    <span class="breadcrumb-item text-capitalize active">{{ $t('projects.reports') }}</span>
                  </nav>
                   <!-- End Breadcrumb -->
                            
                </div>  
                <!-- End Header -->


                <div class="clearfix"></div>
                <div class="col-12 row "
                  :class="(locale == 'ar') ? 'dir-rtl' : ''">
                  <div class="col-md-2 col-sm-12"></div>
                  <div class="col-md-3 col-sm-6">
                    <div class="form-group">
                      <label>{{ $t('reports.from') }}</label>
                      <input type="date" class="form-control" v-model="row.from">
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="form-group">
                      <label>{{ $t('reports.to') }}</label>
                      <input type="date" class="form-control" v-model="row.to">
                    </div>
                  </div>
                  <div class="col-md-1 col-sm-6">
                    <div class="form-group" style="width: 100%">
                      <label class="btn-opacity">Button</label>
                      <button type="button" 
                            @click="reportSearch()" 
                            class="btn btn-danger"
                            style="height: 44px">
                            <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-12"></div>
                </div>

                <div class="card">

                  <!-- Status  -->
                  <div class="card-header">
                    <h5 class="card-title text-muted f14">

                      <router-link :to="{ name: refs }"
                        :class="(status == '') ? 'active' : ''">{{ $t('app.all') }}</router-link> 
                      <span> ({{statusBar.all}}) </span> 
                      <!-- <span class="obstacle"> | </span> -->

                      <!-- <router-link :to="{ name: 'status-'+refs, params:{ status:'active'} }"
                        :class="(status == 'active') ? 'active' : ''">{{ $t('app.active') }}</router-link> 
                      <span> ({{statusBar.active}}) </span> <span class="obstacle"> | </span>

                      <router-link :to="{ name: 'status-'+refs, params:{ status:'inactive'} }"
                        :class="(status == 'inactive') ? 'active' : ''">{{ $t('app.inactive') }}</router-link> 
                      <span> ({{statusBar.inactive}}) </span> <span class="obstacle"> | </span>

                      <router-link :to="{ name: 'status-'+refs, params:{ status:'archive'} }"
                        :class="(status == 'archive') ? 'active' : ''">{{ $t('app.archive') }}</router-link> 
                      <span> ({{statusBar.archive}}) </span> <span class="obstacle"> | </span>

                      <router-link :to="{ name: 'status-'+refs, params:{ status:'trash'} }"
                        :class="(status == 'trash') ? 'active' : ''">{{ $t('app.trash') }}</router-link> 
                      <span> ({{statusBar.trash}}) </span> -->

                    </h5>


                    <div class="col-md-12 text-center">
                      <h4 class="">
                        {{ $t('app.total_donations') }} <br/>
                        <span v-if="!dataLoading"> {{ this.totalDonation }}</span>
                      </h4>
                    </div>
                    <div class="col-md-12"></div>
                      <div class="col-md-10 row" 
                        style="margin-left:0;padding:0;direction: rtl;float:right">
                        <div class="col-md-6">
                          <input type="text" 
                                class="form-control"
                                :placeholder="'بحث'"
                                v-model="search"
                                v-on:keyup.enter="fetchData('',true)">
                        </div>
                        <div class="col-md-3">
                            <select class="form-control" v-model="model" v-on:change="fetchData('',true)">
                                <option value="projects">{{ $t('reports.projects') }}</option>
                                <option value="orphans">{{ $t('reports.orphans') }}</option>
                                <option value="preachers">{{ $t('reports.preachers') }}</option>
                                <option value="postgraduates">{{ $t('reports.postgradutes') }}</option>
                                <option value="endowments">{{ $t('reports.endowments') }}</option>
                                <option value="reliefs">{{ $t('reports.reliefs') }}</option>
                            </select>
                        </div>
                        <div class="col-md-3"></div>
                      </div>

                    <!-- Show Entries -->
                    <div class="dropdown pull-right hidden-sm">

                      <div class="display-inline">
                        <button type="button" 
                              class="btn btn-light btn-sm dropdown-toggle" 
                              id="dropdownMenuButton4" 
                              data-toggle="dropdown">
                          <span> {{ $t('projects.show') }} </span>
                          <span v-if="!showLoading"> {{ show }}</span>
                          <span v-if="showLoading">
                            <div class="loader loader-sm"></div>
                          </span>
                        </button>
                        <div class="dropdown-menu ui-min-w120" aria-labelledby="dropdownMenuButton4">
                          <a class="dropdown-item cursor-pointer"
                            :class="(show == 10) ? 'active' : ''"
                            @click="onShow(10)">10
                          </a>
                          <a class="dropdown-item cursor-pointer"
                            :class="(show == 25) ? 'active' : ''"
                            @click="onShow(25)">25
                          </a>
                          <a class="dropdown-item cursor-pointer"
                            :class="(show == 50) ? 'active' : ''"
                            @click="onShow(50)">50
                          </a>
                          <a class="dropdown-item cursor-pointer"
                            :class="(show == 100) ? 'active' : ''"
                            @click="onShow(100)">100
                          </a>
                          <a class="dropdown-item cursor-pointer"
                            :class="(show == 500) ? 'active' : ''"
                            @click="onShow(500)">500
                          </a>
                          <a class="dropdown-item cursor-pointer"
                            :class="(show == 1000) ? 'active' : ''"
                            @click="onShow(1000)">1000
                          </a>
                        </div>
                      </div>

                    </div>
                    <!-- End Show Entries -->

                  </div>
                  
                  

                  <div class="card-body">
                    <div class="table-responsive">
                      <table id="printMe" class="table" :class="(locale == 'ar') ? 'dir-rtl' : ''">
                        <thead>
                           <tr class="top-border-none">
                              <th scope="col" style="width: 5%">
                                <div class="checkbox">
                                  <input id="selectbl0" class="custom-control-input" 
                                         type="checkbox"
                                         v-model="selectAll" 
                                         @click="select">
                                  <label for="selectbl0"></label>
                                </div>
                              </th>
                              <th scope="col" style="width: 10%">{{ $t('projects.name') }}</th>
                              <th scope="col" class="text-center" style="width: 15%">{{ $t('projects.amount') }}</th>
                              <th scope="col" class="text-center" style="width: 20%">{{ $t('projects.type') }}</th>
                              <th scope="col" class="text-center" style="width: 20%">{{ $t('projects.side') }}</th>
                              <th scope="col" class="text-center" style="width: 15%">{{ $t('projects.date') }}</th>
                            </tr>
                        </thead>
                          
                          <!-- Loader -->
                          <tr v-if="dataLoading">
                            <td colspan="6" class="text-center ui-h100">
                              <div class="loader loader-lg ui-mtop-15"></div>
                            </td>
                          </tr>

                          <!-- No data found -->
                          <tr v-if="!dataLoading && !rows.length">
                            <td colspan="6" class="text-center ui-h100">
                                <span>{{ $t('projects.noData') }}</span>
                            </td>
                          </tr>

                        <!-- Rows -->
                        <tbody v-if="!dataLoading && rows.length">
                          <tr v-for="(row, index) in rows" :key="index"
                            style="font-weight: 700">

                            <td>
                              <div class="checkbox">
                                  <input :id="'selectbl'+row.id" 
                                      class="custom-control-input" 
                                      type="checkbox" 
                                      v-model="selected" 
                                      :value="row.id">
                                  <label :for="'selectbl'+row.id"></label>
                              </div>
                            </td>

                            <!-- Name -->
                            <td class="font-weight-semi-bold">
                                <span class="fweight-600" v-if="!row.user"> - </span>
                                <span class="fweight-600" v-if="row.user">
                                    {{ row.user.first_name }} {{ row.user.last_name }}
                                </span>
                            </td>
                            <!-- End Name -->

                            <td class="text-center">
                              <label > {{ row.amount }} </label>
                            </td>
                            <td class="text-center">
                                <span v-if="row.model_type == 'projects'" class="badge badge-danger">{{ $t('reports.projects') }}</span>
                                <span v-if="row.model_type == 'postgraduates'" class="badge badge-success">{{ $t('reports.postgradutes') }}</span>
                                <span v-if="row.model_type == 'orphans'" class="badge badge-success">{{ $t('reports.orphans') }}</span>
                                <span v-if="row.model_type == 'preachers'" class="badge badge-warning">{{ $t('reports.preachers') }}</span>
                                <span v-if="row.model_type == 'reliefs'" class="badge badge-reliefs">{{ $t('reports.reliefs') }}</span>
                                <span v-if="row.model_type == 'endowments'" class="badge badge-default">{{ $t('reports.endowments') }}</span>
                            </td>

                            <td class="text-center">
                              <label v-if="row.image"> 
                                <img :src="row.image" style="width: 70px;height: 50px">
                              </label>
                              <p></p>
                              <label v-if="row.name" style="margin-right: 10px; margin-left: 10px">
                                {{ row.name }}
                              </label>
                            </td>

                           
                            <!-- Date -->
                            <td v-html="row.date"
                                class="text-center ui-date dir-ltr">
                            </td>
                            <!-- End Date -->

                          </tr>
                        </tbody>
                        <tfoot>
                           <tr class="top-border-none">
                              <th scope="col">
                                <div class="checkbox">
                                  <input id="selectbl0" class="custom-control-input" 
                                         type="checkbox"
                                         v-model="selectAll" 
                                         @click="select">
                                  <label for="selectbl0"></label>
                                </div>
                              </th>
                              <th scope="col">{{ $t('projects.name') }}</th>
                              <th scope="col" class="text-center">{{ $t('projects.date') }}</th>
                              <th scope="col" class="text-center">{{ $t('projects.actions') }}</th>
                            </tr>
                        </tfoot>
                      </table>
                    </div>

                     <!--  <nav  v-if="rows.length !== 0" aria-label="Page navigation example ui-mt20">
                        <ul class="pagination">
                          <li v-bind:class="[{disabled: !pagination.prev_page_url}]" class="page-item">
                            <a class="page-link" href="javascript:" 
                                @click="fetchData(pagination.prev_page_url)">Previous</a>
                          </li>
                          <li v-bind:class="[{disabled: !pagination.next_page_url}]" class="page-item">
                            <a class="page-link" href="javascript:" 
                                @click="fetchData(pagination.next_page_url)">Next</a>
                          </li>
                        </ul>
                        <p class="pull-right ui-mt-30 f13">
                          <i>Page {{ pagination.current_page }} or {{ pagination.last_page }}</i>
                        </p>
                      </nav> -->

                      <paginate v-if="!dataLoading && rows.length !== 0"
                          :page-count="paginateCount"
                          :prev-text="'Prev'"
                          :next-text="'Next'"
                          :container-class="'pagination'"
                          :click-handler="fetchData">
                      </paginate>

                  </div>

                    
                </div>






                <div class="clearfix"><br/><br/></div>

    
              </div>
            </div>

          <Footer></Footer>
        </div>

  </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import iziToast from 'izitoast';

  export default {
    name: 'List',
    components: {
      Header,
      QuickView,
      Footer
    },
    data() {
      return {
        // export
        exp: {
          json_fields: {}, 
          json_data: [],
          json_meta: [
              [{
                'key': 'charset',
                'value': 'utf-8'
              }]
          ],
        },

        // auth
        auth: {
          access_token: '',
          role: '',
        },

        // permissions
        permissions: {
          add: false,
          edit: false,
          delete: false
        },

        // statusBar
        statusBar: {
          all: 0,
          active: 0,
          inactive: 0,
          archive: 0,
          trash: 0,
        },

        //
        
        search: '',
        status: '',
        filter_by: '',
        filter: '',
        order: '',
        order_by: '',

        selected: [],
        selectAll: false,
        plural: '',

        dataLoading: true,
        bulkLoading: true,
        exportLoading: true,
        countryLoading: false,
        showLoading: false,
        orderLoading: false,
        rows: [],
        show: 10,
        pagination: {},
        paginateCount: 10,

        type: 'all_donations',
        model: 'projects',
        fileName: 'all_donations',
        locale: 'ar',

        row: {
          from: '',
          to: '',
        },
        //
        refs: 'reports',
        totalDonation: 0,


            charts: [],
            lineType: 'monthly',
            chartLoading: true,
            chartOption: {
              chart: { height: 450, zoom: { enabled: false }},
              animations: { enabled: true },
              dataLabels: {  enabled: false },
              stroke: {  curve: 'straight' },
              title: { text: '', align: 'center' },
              grid: {  row: { colors: ['#f3f3f3', 'transparent'],  opacity: 0.5 }},
              markers: { size: 5, align:top,  hover: {  sizeOffset: 5  }},
            },

            pieDays: 7,
            pies: [],
            pieLoading: true,
            pieOptions: {
              chart: {
                width: 380,
                type: 'pie',
              },
                responsive: [{
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200
                    },
                    legend: {
                      position: 'bottom'
                    }
                  }
                }]
              },
      }
    },
    mounted() {},
    watch: {
      $route() {
        // Status By
        if(this.$route.params.type) {
          this.type = this.$route.params.type;
        }

        this.fetchExpJson();

        if(this.$route.params.status) {
          this.status = this.$route.params.status;
        } else {
          this.status = '';
        }

        // Filters by
        if(this.$route.params.filter_by) {
          this.filter_by = this.$route.params.filter_by;
        }
        if(this.$route.params.filter) {
          this.filter = this.$route.params.filter;
        }

        // fetch Data
        this.fetchData('', true);
      }
    },
    computed: {},
    created() {
        // AccessToken & Role
        if(localStorage.getItem('locale')) {
            this.locale = localStorage.getItem('locale');
        }
        if(localStorage.getItem('role')) {
            this.auth.role = localStorage.getItem('role');
        }
        if(localStorage.getItem('access_token')) {
            this.auth.access_token = localStorage.getItem('access_token');
        }

        // Status By
        if(this.$route.params.type) {
          this.type = this.$route.params.type;
        }

        if(this.$route.params.status) {
            this.status = this.$route.params.status;
        }

        this.fetchExpJson();

        // Filters by
        if(this.$route.params.filter_by) {
            this.filter_by = this.$route.params.filter_by;
        }
        if(this.$route.params.filter) {
            this.filter = this.$route.params.filter;
        }

        // fetch Data
        this.fetchData('', true);
    },
    methods: {

      // fetchData
      fetchData(page, loading=false) {
          if(loading) { this.dataLoading = true; this.pieLoading = true; this.chartLoading = true; }
          this.plural = '',
          this.selectAll = false;
          this.selected = [];

          if(this.row.from && this.row.to) {
            this.fileName = this.type+' - '+this.row.from+' - '+this.row.to;
          } else if (this.row.from && !this.row.to) {
            this.fileName = this.type+' - '+this.row.from+' - ';
          } else if (this.row.to && !this.row.from) {
            this.fileName = this.type+' - '+' '+' - '+this.row.to;
          } else {
            this.fileName = this.type;
          }

          this.fetchExpJson();

          this.axios.defaults.headers.common = {
              'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
              'Authorization': `Bearer ` + this.auth.access_token,
          };
          const options = {
              url: window.baseURL+'/'+this.refs,
              method: 'GET',
              data: {},
              params: {
                page: page,
                locale: this.locale,
                isPage: 0,
                type: this.type,
                model: this.model,
                from: this.row.from,
                to: this.row.to,
                status: this.status,
                filter_by: this.filter_by,
                filter: this.filter,
                search: this.search,
                paginate: this.show,
                order: this.order,
                order_by: this.order_by
              },
          }
          let vm = this;
          this.axios(options)
            .then(res => {
                this.dataLoading = false;
                this.bulkLoading = false;
                this.exportLoading = false;
                this.showLoading = false;
                this.orderLoading = false;
                this.countryLoading = false;

                // Permissions
                //this.permissions.add = res.data.permissions.add;
                //this.permissions.edit = res.data.permissions.edit;
                //this.permissions.delete = res.data.permissions.delete;

                // StatusBar
                this.statusBar.all = res.data.paginate.total;
                // this.statusBar.active = res.data.statusBar.active;
                // this.statusBar.inactive = res.data.statusBar.inactive;
                // this.statusBar.archive = res.data.statusBar.archive;
                // this.statusBar.trash = res.data.statusBar.trash;

                // rows & paginate
                this.rows = res.data.rows;
                if(res.data.paginate.total) {
                  vm.makePagination(res.data.paginate);
                  this.paginateCount = Math.ceil(res.data.paginate.total / this.show);
                }
                this.totalDonation = res.data.total;

                this.row.from = res.data.from;
                this.row.to = res.data.to;

                
                // this.chartLoading = false;
                // this.charts = [{name:'', data: res.data.lineChart.series}];
                // this.chartOption.xaxis = {categories: res.data.lineChart.xaxis};

                // this.pieLoading = false;
                // this.pies = res.data.pieChart.total;
                // this.pieOptions.labels = res.data.pieChart.contributors;

            })
            .catch(err => {

                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                  this.removeLocalStorage();
                  this.$router.push({ name: 'forbidden' });
                } else {
                  this.dataLoading = false;
                  iziToast.warning({
                    icon: 'ti-ban',
                    title: 'Wow, man',
                    message: (err.response) ? err.response.data.message : ''+err
                  });
                }

            })
            .finally(() => { })
      },


      fetchExpJson() {

        if(this.type == 'all_donations') {
            this.exp.json_fields = {

                'PROJECT ID': 'model_id',
                'PROJECT TITLE': 'name',

                'DONATION DATE': 'date',
                'STATUS' : 'donation_status',

                'PAYMENT MEHTOD' : 'payment_method',
                'TOTAL AMOUNT' : 'amount',
                'CURRENCY': 'currency',
                

                'DONOR FIRST NAME' : 'user.first_name',
                'DONOR LAST NAME' : 'user.last_name',
                'DONOR EMAIL' : 'email_address',
                'DONOR MOBILE' : 'user.mobile',
            };
        }

      },

      // Pagination
      makePagination(meta) {
        let pagination = {
            current_page: meta.current_page,
            last_page: meta.last_page,
            next_page_url: meta.next_page_url,
            prev_page_url: meta.prev_page_url
        }
        this.pagination = pagination;
      },

      
      reportSearch() {
        this.fetchData('', true);
      },

      // Search Submit
      onSearchSubmit(value) {
        this.search = value;
        this.fetchData('', true);
      },

      // Show
      onShow(show){
        this.showLoading = true;
        this.show = show;
        this.fetchData('', true);
      },

      // Order By
      onOrderBy(column){
        this.orderLoading = true;
        this.order_by = column;
        if(this.order == 'ASC') {
          this.order = 'DESC';
        } else {
          this.order = 'ASC';
        }

        this.fetchData('', true);
      },

      // remove Filter
      removeFilter(){
        this.authorLoading = true;
        this.filter = '';
        this.filter_by = '';
        this.$router.push({ name: this.refs });
      },
           
      // remove LocalStorage
      removeLocalStorage() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_id');
        localStorage.removeItem('avatar');
        localStorage.removeItem('role');
      },

      // Fetch Export to Excel, CSV
      async fetchExport(){
         const res = await this.axios.post(window.baseURL+'/reports/export?id='+this.selected+'&type='+this.type+'&model='+this.model+'&locale='+this.locale+'&from='+this.row.from+'&to='+this.row.to);
        return res.data.rows;
      },
      startDownload(){
        this.exportLoading = true;
      },
      finishDownload(){
        this.exportLoading = false;
        iziToast.success({
          icon: 'ti-check',
          title: '',
          message: 'تم إنشاء الملف بنجاح',
        });
      },
      
      /** Bulk Actions **/
          // ON Select
          select() {
            this.selected = [];
              if (!this.selectAll) {
                for (let i in this.rows) {
                  this.selected.push(this.rows[i].id);
                }
              }
            },

            

          

    },
  }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
table thead { background-color: #515365 !important }
table tfoot { display: none !important }
.table > thead > tr > th { color: white !important }
</style>