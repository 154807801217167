<template>
    <div class="">
        
      <footer class="content-footer">
                    <div class="footer">
                        <div class="copyright">
                            <span>
                                <!-- Copyright © 2020 . All rights reserved. -->
                            </span>
                            <span class="go-right">
                                <!-- <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                <a href="#" class="text-gray">Privacy &amp; Policy</a> -->
                            </span>
                        </div>
                    </div>
                </footer>

    </div>
</template>

<script>
    export default {
        name: 'Footer',
        data(){
            return {
              //
            }
        },
        mounted() {},
        created() {},
        methods: {},
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>