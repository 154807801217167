<template>
    <div class="">
        
        <Header></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
                <div class="container-fluid">

                    <!-- Header -->
                    <div class="page-header">

                        <!-- Title -->
                        <h2 class="header-title text-capitalize"
                      :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                      <i class="mdi mdi-lock-open"></i>
                      {{ $t('projects.roles') }}
                  </h2>
                        <router-link :to="{ name: refs }" 
                              class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
                        </router-link>

                        <!-- Role -->
                        <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                            {{ auth.role }}
                        </label>
                        <!-- End Role -->

                        <div class="clearfix"></div>

                        <!-- Breadcrumb -->
                        <nav class="breadcrumb breadcrumb-dash"
                            :class="(locale == 'ar') ? 'dir-rtl' : ''">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="breadcrumb-item">
                                <i class="ti-home p-r-5"></i>
                                {{ $t('projects.dashboard') }}
                            </router-link>
                            <router-link :to="{ name: refs }" 
                                class="breadcrumb-item text-capitalize">{{ $t('projects.roles') }}
                            </router-link>
                            <span class="breadcrumb-item active">{{ $t('projects.add_new') }}</span>
                        </nav>
                        <!-- End Breadcrumb -->
                       
                    </div>  
                    <!-- End Header -->

            <div v-if="pgLoading" class="row h-100">
                <div class="container text-center">
                    <div class="loader loader-lg ui-mtop-15"></div>
                </div>
            </div> 
                        
            <form v-if="!pgLoading" @submit.prevent="addNew" enctype="multipart/form-data"> 
                <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">

                    <div class="col-md-8">

                        <!-- Content -->
                        <div class="card">
                            <div class="row">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-11 ml-auto mr-auto">

                                            <div class="p-v-20">
                                                <div class="form-group">
                                                    <label class="control-label">{{ $t('projects.name') }}</label>
                                                    <input type="text"
                                                        class="form-control"
                                                        @keydown.space.prevent 
                                                        v-model="row.name">
                                                </div>
                                            </div>
                                    

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Content -->


                        <!-- Permissions -->
                        <div class="card">
                            <div class="row">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-11 ml-auto mr-auto">

                                            <div class="m-b-10 form-group">  
                                                <label class="control-label">{{ $t('projects.permissions') }}<br/><br/></label>
                                            </div>  

                                            <div v-if="permissionLoading" 
                                                class="form-group col-12 text-center">
                                                <div class="loader loader-lg ui-mtop-15"></div>
                                            </div>

                                            <div class="form-group col-10"
                                                v-for="(permission, index) in permissions"
                                                :key="index">
                                                <label class="mb-3 mt-3 control-label">
                                                    <span v-if="(locale == 'ar')" 
                                                        style="font-size: 16px;padding:10px" 
                                                        class="badge badge-dark">
                                                        <span v-if="(index == 'categories')">
                                                            {{ $t('projects.pgCategories') }}
                                                        </span>
                                                        <span v-if="(index == 'projects')">
                                                            {{ $t('projects.pgName') }}
                                                        </span>
                                                        <span v-if="(index == 'orphans')">
                                                            {{ $t('projects.pgOrphans') }}
                                                        </span>
                                                        <span v-if="(index == 'postgraduates')">
                                                            {{ $t('projects.pgPostgraduates') }}
                                                        </span>
                                                        <span v-if="(index == 'preachers')">
                                                            {{ $t('projects.pgPreachers') }}
                                                        </span>
                                                        <span v-if="(index == 'reliefs')">
                                                            {{ $t('projects.pgReliefs') }}
                                                        </span>
                                                        <span v-if="(index == 'endowments')">
                                                            {{ $t('projects.pgEndowments') }}
                                                        </span>
                                                        <span v-if="(index == 'about')">
                                                            {{ $t('projects.pgAbout') }}
                                                        </span>
                                                        <span v-if="(index == 'gallery')">
                                                            {{ $t('projects.gallery') }}
                                                        </span>
                                                        <span v-if="(index == 'multimedia')">
                                                            {{ $t('projects.multimedia') }}
                                                        </span>
                                                        <span v-if="(index == 'donations')">
                                                            {{ $t('projects.pgDonations') }}
                                                        </span>

                                                        <span v-if="(index == 'inbox')">
                                                            {{ $t('app.inbox') }}
                                                        </span>
                                                        <span v-if="(index == 'roles')">
                                                            {{ $t('projects.roles') }}
                                                        </span>
                                                        <span v-if="(index == 'staff')">
                                                            {{ $t('projects.staff') }}
                                                        </span>
                                                        <span v-if="(index == 'accounts')">
                                                            {{ $t('projects.pgAccounts') }}
                                                        </span>
                                                        <span v-if="(index == 'settings')">
                                                            {{ $t('projects.settings') }}
                                                        </span>
                                                        <span v-if="(index == 'countries')">
                                                            {{ $t('projects.pgCountries') }}
                                                        </span>
                                                        <span v-if="(index == 'pages')">
                                                            {{ $t('projects.pages') }}
                                                        </span>
                                                        <span v-if="(index == 'reports')">
                                                            {{ $t('projects.reports') }}
                                                        </span>
                                                        <span v-if="(index == 'periodicReports')">
                                                            {{ $t('nav.periodicReports') }}
                                                        </span>
                                                        <span v-if="(index == 'sliders')">
                                                            {{ $t('projects.sliders') }}
                                                        </span>
                                                        <span v-if="(index == 'socials')">
                                                            {{ $t('projects.socials') }}
                                                        </span>
                                                        <span v-if="(index == 'types')">
                                                            {{ $t('projects.donationTypes') }}
                                                        </span>
                                                        <span v-if="(index == 'values')">
                                                            {{ $t('projects.donationValues') }}
                                                        </span>
                                                        <span v-if="(index == 'contributors')">
                                                            {{ $t('projects.pgContributors') }}
                                                        </span>
                                                        <span v-if="(index == 'volunteers')">
                                                            {{ $t('projects.pgVolunteers') }}
                                                        </span>
                                                        <span v-if="(index == 'teams')">
                                                            {{ $t('projects.pgTeams') }}
                                                        </span>
                                                    </span>
                                                    <span v-else style="font-size: 16px" class="badge badge-dakr">
                                                        {{ index }}
                                                    </span>
                                                </label>
                                                <div class="row col-12 text-center" 
                                                    style="margin-left: 50px">
                                                    <div v-for="(item, idx) in permission.length" 
                                                        :key="idx"
                                                        class="custom-control custom-switch col-3">
                                                        <input type="checkbox" 
                                                            class="custom-control-input" 
                                                            @change="isChecked($event, permission[idx].id)"
                                                            :id="permission[idx].id"
                                                            :value="permission[idx].id">
                                                        <label class="custom-control-label"
                                                            style="font-size: 15px; margin-left: -40px" 
                                                            :for="permission[idx].id">
                                                        <span v-if="(locale == 'ar')">
                                                            <span v-if="(permission[idx].name == 'view')">
                                                                {{ $t('role.view') }}
                                                            </span>
                                                            <span v-if="(permission[idx].name == 'add')">
                                                                {{ $t('role.add') }}
                                                            </span>
                                                            <span v-if="(permission[idx].name == 'edit')">
                                                                {{ $t('role.edit') }}
                                                            </span>
                                                            <span v-if="(permission[idx].name == 'delete')">
                                                                {{ $t('role.delete') }}
                                                            </span>
                                                        </span>
                                                        <span v-else>{{ permission[idx].name }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <p> <br/> </p>
                                                <hr>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Permissions -->


                        <!-- Button -->
                        <div class="form-group">
                            <button class="btn btn-dark btn-size" :disabled="btnLoading">
                                <span v-if="btnLoading">
                                    <div class="loader loader-xs loader-center"></div>
                                </span>
                                <span v-if="!btnLoading">{{ $t('projects.create') }}</span>
                            </button>
                            <button type="button" @click="cancel" :disabled="btnLoading"
                                class="btn btn-default btn-size p-10">
                                {{ $t('projects.cancel') }}
                            </button>
                        </div>  
                        <!-- End Buttons -->

                    </div>

                    <div class="col-md-4">

                        <!-- Status -->
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <div class="m-b-10">  
                                        <label class="control-label">{{ $t('projects.status') }}</label>
                                    </div>   
                                    <div class="radio d-inline m-r-15">
                                        <input id="statusRadio1" 
                                            type="radio"
                                            v-model="row.status"
                                            value="1" 
                                            :checked="row.status">
                                        <label for="statusRadio1"> {{ $t('projects.active') }} </label>
                                    </div>
                                    <div class="radio d-inline m-r-15">
                                        <input id="statusRadio2" 
                                            type="radio"
                                            v-model="row.status"
                                            value="0"
                                            :checked="row.status">
                                        <label for="statusRadio2"> {{ $t('projects.inactive') }} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Status -->

                    </div>

                </div>
            </form>


                </div>
            </div>

        <Footer></Footer>
        </div>

    </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import iziToast from 'izitoast';

  export default {
    name: 'Create',
    components: {
      Header,
      QuickView,
      Footer,
    },
    mounted() {},
    data() {
      return {
        //
        auth: {
            access_token: '',
            role: '',
        },
        row: {
            // row
            name: '',

            // permissions
            permissions_ids: [],

            // status & visiblity
            status: 1
        },
        editor: {
            api_key: window.editor_apiKey,
            menubar: window.editor_menubar,
            plugins:[window.editor_plugins],
            toolbar: window.editor_toolbar,
        },

        pgLoading: false,
        btnLoading: false,

        permissions: [],
        permissionLoading: true,
        isClicked: true,
        locale: 'ar',
        refs: 'roles',
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if(localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if(localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      }

      //
      this.fetchPermissions();
    },
    methods: {

        // fetch Permissions
        fetchPermissions() {
            this.permissionLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/permissions',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale
                },
            }
            this.axios(options)
            .then(res => {
                this.permissionLoading = false;
                this.permissions = res.data.rows;
            })
            .catch(() => {})
            .finally(() => {});
        },

        // add New
        addNew() {
            this.isClicked = false; // disabled alert msg
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/'+this.refs,
                method: 'POST',
                data: {
                    // row
                    name: this.row.name,

                    // permissions Ids
                    permissions_ids: this.row.permissions_ids,

                    // status & visibility
                    status: this.row.status
                }
            }
        
            this.$axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: '',
                    message: 'تم الإضافة بنجاح',
                });
                this.$router.push({ name: this.refs });
                this.isClicked = true; // enabled alert msg
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });

                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
                this.isClicked = true; // enabled alert msg
            })
            .finally(() => { })
        },

        // remove LocalStorage
        removeLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('avatar');
            localStorage.removeItem('role');
        },

        // get id from Box Checked
        isChecked($event, id){
            if($event.target.checked) {
                this.row.permissions_ids.push(id)
            } else {
                this.row.permissions_ids.splice(this.row.permissions_ids.indexOf(id), 1)
            }
        },


        // Upload image
        onImageChange(e){
            const file = e.target.files[0];
            this.row.preview = URL.createObjectURL(file);
            this.createBase64Image(file);
        },
        createBase64Image(fileObject){
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);
            reader.onload = e =>{
                this.row.image = e.target.result;
            };
        },


        cancel() {
            if(confirm('هل تريد المغادرة ؟')) {
                 this.isClicked=false;
                this.$router.push({ name: this.refs })
            }
        },
    },

        beforeRouteLeave(to, from, next) {
            if(this.isClicked) {
                if(confirm('هل تريد المغادرة ؟')) {
                    next();
                }
            } else {
                next();
            }
        },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>