<template>
  <div class="">
    <div
      class="layout"
      style="
        background: url('/assets/images/bg/login.jpg');
        background-size: 100% 100%;
      "
    >
      <div class="container">
        <div class="row full-height align-items-center">
          <div class="col-md-5 mx-auto">
            <div class="card">
              <div class="card-body">
                <div class="p-15">
                  <div class="m-b-30 text-center d-flex justify-content-center">
                    <router-link :to="{ name: 'login' }">
                      <img
                        class="img-responsive inline-block"
                        src="/assets/images/logo/fav.jpg"
                        style="height: 200px"
                        alt="charity"
                      />
                    </router-link>
                  </div>

                  <form @submit.prevent="login">
                    <div class="form-group text-right">
                      <label class="control-label">{{
                        $t("projects.email")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="row.email"
                        required=""
                      />
                    </div>

                    <div class="form-group text-right">
                      <label class="control-label">{{
                        $t("projects.password")
                      }}</label>
                      <input
                        type="password"
                        class="form-control"
                        v-model="row.password"
                        autocomplete="off"
                        required=""
                      />
                    </div>

                    <!-- <div class="checkbox font-size-13 d-inline-block pull-right">
                                        <input id="keepMe" 
                                            v-model="row.keepMeAlive" 
                                            @click="keepMeAlive"
                                            type="checkbox">
                                        <label for="keepMe">{{ $t('auth.keep_me_loggedin') }}</label>
                                    </div> -->

                    <div class="pull-right">
                      <!-- <router-link :to="{name: 'forget'}" 
                                            class="text-underline">{{ $t('auth.forget_password') }}
                                        </router-link> -->
                    </div>

                    <div
                      class="m-t-30 go-right"
                      :class="locale == 'ar' ? 'dir-rtl' : 'text-left'"
                    >
                      <button
                        class="btn btn-size f16"
                        style="background-color: #325956"
                      >
                        <span v-if="btnLoading">
                          <div class="loader loader-xs loader-center"></div>
                        </span>
                        <span class="text-white" v-if="!btnLoading">{{
                          $t("auth.login")
                        }}</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "login",
  data() {
    return {
      row: {
        email: "",
        password: "",
        keepMeAlive: false,
      },
      btnLoading: false,
      locale: "ar",
    };
  },
  computed: {},
  created() {
    // set default
    this.$i18n.locale = this.locale;
    this.$i18n.local = this.locale;
    // if(localStorage.getItem('locale')) {
    //     this.locale = localStorage.getItem('locale');
    // }
  },
  methods: {
    // Keep me alive
    keepMeAlive() {
      if (this.keepMeAlive) {
        this.keepMeAlive = false;
      } else {
        this.keepMeAlive = true;
      }
    },

    // Login
    login() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      };
      const options = {
        url: window.baseURL + "/auth/login",
        method: "POST",
        data: {
          email: this.row.email,
          password: this.row.password,
          keepMeAlive: this.row.keepMeAlive,
        },
      };
      this.$axios(options)
        .then((res) => {
          this.btnLoading = false;
          this.row.password = "";

          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("user_name", res.data.user.name);
          localStorage.setItem("user_id", res.data.user.encrypt_id);
          localStorage.setItem("avatar", res.data.user.image.url);
          localStorage.setItem("role", res.data.user.role);

          // Set default Navigation
          // localStorage.setItem('nav_roles', 'show');

          this.$router.push({ name: "dashboard" });
        })
        .catch(() => {
          this.btnLoading = false;
          this.row.password = "";
          iziToast.warning({
            icon: "ti-alert",
            title: "",
            message: "Unauthorized",
          });
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>