<template>
    <div class="">
        
        <Header></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
                <div class="container-fluid">

                    <!-- Header -->
                    <div class="page-header">

                        <!-- Title -->
                        <h2 class="header-title text-capitalize"
                          :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                          <i class="mdi mdi-newspaper"></i>
                      {{ $t('projects.projectNomination') }}
                        </h2>
                        <router-link :to="{ name: refs }" 
                              class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
                        </router-link>
                        <!-- <router-link :to="{ name: 'payments', 
                            params: {model: 'projects', model_id: $route.params.id}}" 
                              class="btn btn-danger btn-rounded btn-bold">
                              {{ $t('projects.payment_links') }}
                        </router-link> -->

                        <!-- Role -->
                        <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                            {{ auth.role }}
                        </label>
                        <!-- End Role -->

                        <div class="clearfix"></div>

                        <!-- Breadcrumb -->
                        <nav class="breadcrumb breadcrumb-dash"
                            :class="(locale == 'ar') ? 'dir-rtl' : ''">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="breadcrumb-item">
                                <i class="ti-home p-r-5"></i>
                                {{ $t('projects.dashboard') }}
                            </router-link>
                            <router-link :to="{ name: refs }" 
                                class="breadcrumb-item text-capitalize"
                                :class="(locale == 'ar') ? 'dir-rtl' : ''">
                                {{ $t('projects.projectNomination') }}
                            </router-link>
                            <span class="breadcrumb-item active">{{ $t('projects.edit') }}</span>
                        </nav>
                        <!-- End Breadcrumb -->
                       
                    </div>  
                    <!-- End Header -->

            <div v-if="pgLoading" class="row h-100">
                <div class="container text-center">
                    <div class="loader loader-lg ui-mtop-15"></div>
                </div>
            </div> 
                        
            <form v-if="!pgLoading" @submit.prevent="editRow" enctype="multipart/form-data"> 
                <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">

                    <div class="col-md-12">

                        <!-- Content -->
                        <div class="card">
                            <div class="row">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-11 ml-auto mr-auto">

                                    
                                    <!-- Languages -->
                                    <div class="tab-danger">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item">
                                                <a href="#tab-arabic" 
                                                    class="nav-link active show" 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="true">{{ $t('app.arabic') }}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="#tab-english" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('app.english') }}</a>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#tab-others" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('projects.pro_info') }}</a>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#tab-image" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('projects.pro_image') }}</a>
                                            </li>

                                            

                                            
                                        </ul>

                                        <div class="tab-content">
                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab-english">

                                                <div class="p-v-20">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.pro_name') }}</label>
                                                        <input type="text"
                                                            class="form-control" 
                                                            v-model="row.title_en">
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.pro_desc') }}</label>
                                                        <editor
                                                            id="editor1"
                                                            v-model="row.body_en"
                                                            :api-key="editor.api_key"
                                                            :init="{
                                                                height: 300,
                                                                menubar: editor.menubar,
                                                                plugins: editor.plugins,
                                                                toolbar: editor.toolbar
                                                            }"
                                                        />
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.slug') }}</label>
                                                        <input type="text" 
                                                            class="form-control text-lowercase" 
                                                            v-on:keypress="isLetter($event)"
                                                            v-model="row.slug_en"
                                                            @keydown.space.prevent 
                                                            disabled="true"
                                                            @paste="onSlugPaste"
                                                            @change="onSlugChange(false)">
                                                            <p style="font-size: 11px;font-style: italic">
                                                                english only
                                                            </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div role="tabpanel" 
                                                class="tab-pane fade active show" 
                                                id="tab-arabic">
                                                
                                                <div class=" p-v-20">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.pro_name') }}</label>
                                                        <input type="text"
                                                            class="form-control" 
                                                            v-model="row.title_ar">
                                                    </div>
                                                    <!-- <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.slug') }}</label>
                                                        <input type="text" 
                                                            class="form-control text-lowercase" 
                                                            v-on:keypress="isLetter($event)"
                                                            v-model="row.slug_ar"
                                                            @keydown.space.prevent 
                                                            @paste="onSlugPaste"
                                                            @change="onSlugChange(false)">
                                                            <p style="font-size: 11px;font-style: italic">
                                                                english only
                                                            </p>
                                                    </div> -->
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.pro_desc') }}</label>
                                                        <editor
                                                            id="editorar1"
                                                            v-model="row.body_ar"
                                                            :api-key="editor.api_key"
                                                            :init="{
                                                                height: 300,
                                                                menubar: editor.menubar,
                                                                plugins: editor.plugins,
                                                                toolbar: editor.toolbar
                                                            }"
                                                        />
                                                    </div>
                                                   
                                                    
                                                </div>

                                            </div>

                                            

                                            <div role="tabpanel" 
                                                    class="tab-pane fade in" 
                                                    id="tab-image">

                                                    <div class="p-v-20">
                                                        <div class="form-group">
                                                            <label class="control-label">
                                                                <button v-if="row.preview"
                                                                    type="button" 
                                                                    class="btn btn-danger btn-sm btn-rounded btn-bold"
                                                                    style="margin-top: 5px" 
                                                                    @click="row.preview = ''; row.image=''">
                                                                    {{ $t('projects.remove') }}
                                                                </button>
                                                            </label>
                                                            <p v-if="row.preview">
                                                                <img :src="row.preview" 
                                                                style="max-width: 100%;height: 200px">
                                                            </p>
                                                            <p><br/></p>
                                                            <div class="btn btn-primary btn-sm file">
                                                                <i class="fa fa-upload"></i>
                                                                {{ $t('projects.pro_image_up') }}
                                                                <input type="file"
                                                                    ref="myDropify"
                                                                    accept="image/*"
                                                                     v-on:change="onImageChange">
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>



                                                <div role="tabpanel" 
                                                    class="tab-pane fade in" 
                                                    id="tab-others">
                                                    <div class="p-v-20 col-12 row">

                                                        <div class="form-group col-6">
                                                            <label class="control-label">{{ $t('projects.category') }}</label>
                                                            <div v-if="categoryLoading" class="text-center">
                                                                <div class="loader loader-xs"></div>
                                                            </div>
                                                            <select v-if="!categoryLoading" 
                                                                    class="form-control" v-model="row.category_id">
                                                                <option value="">{{ $t('projects.select_category') }}</option>
                                                                <option v-for="(cat, index) in categories"
                                                                        :value="cat.id"
                                                                        :key="index" 
                                                                        >{{ cat.name }}</option>
                                                            </select>
                                                        </div>

                                                        <!-- <div class="form-group col-3">
                                                            <label class="control-label">{{ $t('projects.country') }}</label>
                                                            <div v-if="countryLoading" class="text-center">
                                                                <div class="loader loader-xs"></div>
                                                            </div>
                                                            <select v-if="!countryLoading" 
                                                                    class="form-control" 
                                                                    v-model="row.country_id"
                                                                    @change="fetchCities()">
                                                                <option value="">{{ $t('projects.select_country') }}</option>
                                                                <option v-for="(con, index) in countries" 
                                                                        :key="index"
                                                                        :value="con.id">
                                                                        {{ con.name }}
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <div class="form-group col-3">
                                                            <label class="control-label">{{ $t('projects.city') }}</label>
                                                            <div v-if="cityLoading" class="text-center">
                                                                <div class="loader loader-xs"></div>
                                                            </div>
                                                            <select v-if="!cityLoading" 
                                                                    class="form-control" 
                                                                    v-model="row.city_id">
                                                                <option value="">{{ $t('projects.select_city') }}</option>
                                                                <option v-for="(city, idx) in cities"
                                                                        :key="idx"
                                                                        :value="city.id">
                                                                        {{ city.name }}
                                                                </option>
                                                            </select>
                                                        </div> -->


                                                        <div class="form-group col-6">
                                                            <label class="control-label">{{ $t('projects.target') }}</label>
                                                            <div v-if="valueLoading" class="text-center">
                                                                <div class="loader loader-xs"></div>
                                                            </div>
                                                            <select v-if="!valueLoading" 
                                                                    class="form-control" 
                                                                    v-model="row.value_id">
                                                                <option value="">{{ $t('projects.target') }}</option>
                                                                <option v-for="(val, index) in values" 
                                                                        :key="index"
                                                                        :value="val.id">
                                                                        {{ val.amount }}
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <!-- <div class="form-group col-6">
                                                        <label class="control-label">
                                                            {{ $t('projects.sort') }}
                                                        </label>
                                                        <input class="form-control" 
                                                            type="number"
                                                            min="0" 
                                                            v-model.number="row.sort">
                                                    </div> -->
                                                    </div>

                                            </div>


                                        </div>
                                    </div>
                                    <!-- End Languages -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Content -->

                        <div class="form-group">
                            <button class="btn btn-dark btn-size" :disabled="btnLoading">
                                <span v-if="btnLoading">
                                    <div class="loader loader-xs loader-center"></div>
                                </span>
                                <span v-if="!btnLoading">{{ $t('projects.update') }}</span>
                            </button>
                            <button type="button" @click="cancel" :disabled="btnLoading"
                                class="btn btn-default btn-size p-10">
                                {{ $t('projects.cancel') }}
                            </button>
                        </div>  


                    </div>


                </div>
            </form>


                </div>
            </div>

        <Footer></Footer>
        </div>

    </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import Editor from '@tinymce/tinymce-vue';
  import iziToast from 'izitoast';
  //import Multiselect from 'vue-multiselect';

  export default {
    name: 'Edit',
    components: {
      Header,
      QuickView,
      Footer,
      Editor,
      //Multiselect
    },
    mounted() {},
    data() {
      return {
        //
        auth: {
            access_token: '',
            role: '',
        },
        row: {
            // row
            slug_en: '',
            title_en: '',
            body_en: '',
        
            slug_ar: '',
            title_ar: '',
            body_ar: '',


            // image
            preview: '',
            image: '',

            // status & visiblity
            value_id: 0,

            category_id: '',

            country_id: '',
            city_id: '',
            sort: 0,
            status: 0,
        },
        editor: {
            api_key: window.editor_apiKey,
            menubar: window.editor_menubar,
            plugins:[window.editor_plugins],
            toolbar: window.editor_toolbar,
        },

        categoryLoading: true,
        categories: [],

        countryLoading: true,
        countries: [],

        cityLoading: false,
        cities: [],

        valueLoading: false,
        values: [],

        pgLoading: true,
        btnLoading: false,

        isClicked: true,
        locale: 'ar',
        refs: 'projectNomination',
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if(localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if(localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      }

      //

      this.fetchRow();
      this.fetchCategories();
      //this.fetchCountries();
      this.fetchValues();
    },
    methods: {

        fetchCategories() {
            this.categoryLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/categories',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: true,
                    paginate: 100
                }
            }
            this.$axios(options)
            .then(res => {
                this.categoryLoading = false;
                this.categories = res.data.rows;
            })
            .catch(() => {})
            .finally(() => {});
        },

        // fetch Row
        fetchRow() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/'+this.refs+'/'+this.$route.params.id,
                method: 'GET',
                data: {}
            }
            this.$axios(options)
            .then(res => {
                this.pgLoading = false;

                // row
                this.row.title_en = res.data.row.en.title;
                this.row.slug_en = res.data.row.en.slug;
                this.row.body_en = res.data.row.en.body;

                this.row.title_ar = res.data.row.ar.title;
                this.row.slug_ar = res.data.row.ar.slug;
                this.row.body_ar = res.data.row.ar.body;
               
                
                // image
                this.row.preview = (res.data.row.image) ? res.data.row.image.url : null;
                this.row.image = (res.data.row.image) ? res.data.row.image.url : null;

                // category
                this.row.category_id = (res.data.row.category) ? res.data.row.category.id : null;
                //this.row.country_id = (res.data.row.country) ? res.data.row.country.id : null;
                //this.row.city_id = (res.data.row.city) ? res.data.row.city.id : null;

                // status & visiblity
                this.row.value_id = (res.data.row.value) ? res.data.row.value.id : null;
                this.row.sort = res.data.row.sort;
                this.row.status = res.data.row.status;
                //
                
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.pgLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });                
                }
            })
            .finally(() => {});
        },

        fetchValues() {
            this.valueLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/projectsNominationValue',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: true,
                    paginate: 100
                }
            }
            this.$axios(options)
            .then(res => {
                this.valueLoading = false;
                this.values = res.data.rows;
                
                //this.fetchContributors();
            })
            .catch(() => {})
            .finally(() => {});
        },

      

        fetchCountries() {
            this.countryLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/countries',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: true,
                    paginate: 100
                }
            }
            this.$axios(options)
            .then(res => {
                this.countryLoading = false;
                this.countries = res.data.rows;

               
                this.fetchCities();
            })
            .catch(() => {})
            .finally(() => {});
        },

        fetchCities() {
            this.cityLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/countries',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: true,
                    paginate: 100,
                    country_id: this.row.country_id,
                }
            }
            this.$axios(options)
            .then(res => {
                this.cityLoading = false;
                this.cities = res.data.rows;
            })
            .catch(() => {})
            .finally(() => {});
        },

      
        // edit Row
        editRow() {
            this.isClicked = false; // disabled alert msg
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };



            const options = {
                url: window.baseURL+'/'+this.refs+'/'+this.$route.params.id,
                method: 'PUT',
                data: {
                    // row
                    en: {
                        slug: this.row.slug_en,
                        title: this.row.title_en,
                        body: this.row.body_en,
                    },
                    ar: {
                        slug: this.row.slug_en,
                        title: this.row.title_ar,
                        body: this.row.body_ar,
                    },
                    
                  

                    // image
                    image: this.row.image,

                    // status & visibility
                    value_id: this.row.value_id,
                    category_id: this.row.category_id,
                    country_id: this.row.country_id,
                    city_id: this.row.city_id,
                    sort: this.row.sort,
                    status: this.row.status
                }
            }
        
            this.$axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: '',
                    message: 'تم التعديل بنجاح',
                });
                this.$router.push({ name: this.refs });
                this.isClicked = true; // enabled alert msg
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });

                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
                this.isClicked = true; // enabled alert msg
            })
            .finally(() => { })
        },

        // remove LocalStorage
        removeLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('avatar');
            localStorage.removeItem('role');
        },

         // Slug Change
        onTitleChange() {
            this.onSlugChange(this.row.title_en);
        },
        onSlugPaste(){
            let str = this.row.slug_en;
            this.onSlugChange(str);
        },
        onSlugChange(str){
            if(!str) {
                let str = this.row.slug_en;
                this.row.slug_en = str.replace(/\s+/g, '-');
                this.row.slug_ar = str.replace(/\s+/g, '-');
            } else {
                this.row.slug_en = str.replace(/\s+/g, '-');
                this.row.slug_ar = str.replace(/\s+/g, '-');
            }
        },


       
        // Upload image
        onImageChange(e){
            const file = e.target.files[0];
            this.row.preview = URL.createObjectURL(file);
            this.createBase64Image(file);
        },
        createBase64Image(fileObject){
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);
            reader.onload = e =>{
                this.row.image = e.target.result;
            };
        },

        isLetter(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        },

        cancel() {
            if(confirm('هل تريد المغادرة ؟')) {
                this.isClicked=false;
                this.$router.push({ name: this.refs })
            }
        },
    },

        beforeRouteLeave(to, from, next) {
            if(this.isClicked) {
                if(confirm('هل تريد المغادرة ؟')) {
                    next();
                }
            } else {
                next();
            }
        },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>