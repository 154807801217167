<template>
  <div id="app">
     <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
}
</style>
