<template>
    <div class="">
        
      <Header></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
                <div class="container-fluid">

                    <!-- Header -->
                    <div class="page-header">

                        <!-- Title -->
                        <h2 class="header-title text-capitalize"
                      :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                      <i class="mdi mdi-message-text"></i>
                      {{ $t('app.inbox') }}
                  </h2>
                        <router-link :to="{ name: ref }" 
                              class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
                        </router-link>

                        <!-- Role -->
                        <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                            {{ auth.role }}
                        </label>
                        <!-- End Role -->

                        <div class="clearfix"></div>

                        <!-- Breadcrumb -->
                        <nav class="breadcrumb breadcrumb-dash"
                            :class="(locale == 'ar') ? 'dir-rtl' : ''">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="breadcrumb-item">
                                <i class="ti-home p-r-5"></i>
                                {{ $t('app.dashboard') }}
                            </router-link>
                            <router-link :to="{ name: ref }" 
                                class="breadcrumb-item text-capitalize">{{ $t('app.inbox') }}
                            </router-link>
                            <span class="breadcrumb-item active">{{ $t('projects.read_msg') }}</span>
                        </nav>
                        <!-- End Breadcrumb -->
                       
                    </div>  
                    <!-- End Header -->

                <div v-if="pgLoading" class="row h-100">
                    <div class="container text-center">
                        <div class="loader loader-lg ui-mtop-15"></div>
                    </div>
                </div> 

                <form v-if="!pgLoading" @submit.prevent="reply" enctype="multipart/form-data"> 
                    <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">
                        
                        <div class="col-md-12">
                            <div class="card">
                                <div class="row">
                                        
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-11 ml-auto mr-auto row">


                                <div class="p-v-20 col-6">
                                        <div class="form-group">
                                            <label class="control-label">{{ $t('projects.name') }}</label>
                                            <input type="text"
                                                disabled="" 
                                                class="form-control" 
                                                v-model="row.name">
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label">{{ $t('projects.email') }}</label>
                                            <input type="text" 
                                                disabled="" 
                                                class="form-control" 
                                                v-model="row.email">
                                        </div>
                                </div>
                                <div class="p-v-20 col-6">
                                        <div class="form-group">
                                            <label class="control-label">{{ $t('projects.mobile') }}</label>
                                            <input type="text"
                                                disabled="" 
                                                class="form-control dir-ltr" 
                                                v-model="row.mobile">
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label">{{ $t('projects.subject') }}</label>
                                            <input type="text" 
                                                disabled="" 
                                                class="form-control" 
                                                v-model="row.subject">
                                        </div>
                                </div>
                                <div class="p-v-20 col-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('projects.message') }}</label>
                                        <textarea rows="10" 
                                                disabled="" 
                                                class="form-control" 
                                                v-model="row.body">
                                        </textarea>
                                    </div>
                                </div>
       
                                                  
                                                   

                                    </div>
                                </div>
                            </div>

                    </div>
                </div>


                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-11 ml-auto mr-auto row">

                                <div class="p-v-20 col-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('projects.reply') }}</label>
                                        <textarea rows="10" 
                                                class="form-control" 
                                                v-model="row.reply"
                                                required="">
                                        </textarea>
                                    </div>
                                </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="card-body">
                             <div class="form-group p-t-20">
                                <button class="btn btn-dark btn-size" :disabled="btnLoading">
                                    <span v-if="btnLoading">
                                        <div class="loader loader-xs loader-center"></div>
                                    </span>
                                    <span v-if="!btnLoading">{{ $t('projects.reply') }}</span>
                                </button>
                                <router-link :to="{ name: ref }" :disabled="btnLoading"
                                    class="btn btn-default">{{ $t('projects.cancel') }}
                                </router-link>
                            </div>  
                        </div>
                    </div>

                </div>

          

                </div>
            </form>


                </div>
            </div>

          <Footer></Footer>
        </div>

  </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import iziToast from 'izitoast';

  export default {
    name: 'Edit',
    components: {
      Header,
      QuickView,
      Footer
    },
    mounted() {},
    data() {
      return {
        //
        auth: {
            access_token: '',
            role: '',
        },
        row: {
            // row
            name: '',
            email: '',
            mobile: '',
            subject: '',
            body: '',

            reply: '',
        },

        pgLoading: true,
        btnLoading: false,
        locale: 'ar',
        ref: 'inbox',
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if(localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if(localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      }

      //
      this.fetchRow();
    },
    methods: {

      // fetch Row
      fetchRow() {
        this.pgLoading = true;
        this.axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            'Authorization': `Bearer ` + this.auth.access_token,
        };
        const options = {
            url: window.baseURL+'/'+this.ref+'/'+this.$route.params.id,
            method: 'GET',
            data: {}
        }
        this.$axios(options)
            .then(res => {
                this.pgLoading = false;

                // row
                this.row.name = res.data.row.name;
                this.row.email = res.data.row.email;
                this.row.mobile = res.data.row.mobile;
                this.row.subject = res.data.row.subject;
                this.row.body = res.data.row.body;
                this.row.reply = res.data.row.reply;
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.pgLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });                
                }
            })
            .finally(() => {});
      },

      // edit Row
      reply() {
        this.btnLoading = true;
        this.axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            'Authorization': `Bearer ` + this.auth.access_token,
        };
        const options = {
            url: window.baseURL+'/'+this.ref+'/'+this.$route.params.id+'?reply',
            method: 'PUT',
            data: {
                reply: this.row.reply,
            }
        }
        
        this.$axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: '',
                    message: 'Message Replied Successfully',
                });
                this.$router.push({ name: this.ref });
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });

                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
            })
            .finally(() => { })
        },

        // remove LocalStorage
        removeLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('avatar');
            localStorage.removeItem('role');
        },

       

    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>