<template>
    <div class="">
        
        <Header></Header>

        <div class="page-container">
          <QuickView></QuickView>

            <!-- Content Wrapper START -->
            <div class="main-content">
                <div class="container-fluid">

                    <!-- Header -->
                    <div class="page-header">

                        <!-- Title -->
                        <h2 class="header-title text-capitalize"
                              :class="(locale == 'ar') ? 'dir-rtl go-right' : ''">
                              <i class="mdi mdi-human-male-female"></i>
                              {{ $t('projects.pgReliefs') }}
                        </h2>
                        <router-link :to="{ name: refs }" 
                              class="btn btn-primary btn-rounded btn-bold btn-opacity">Back
                        </router-link>
                        <!-- <router-link :to="{ name: 'payments', 
                            params: {model: 'reliefs', model_id: $route.params.id}}" 
                              class="btn btn-danger btn-rounded btn-bold">
                              {{ $t('projects.payment_links') }}
                        </router-link> -->

                        <!-- Role -->
                        <label class="badge badge-success-soft badge-xl btn-rounded btn-bold pull-right">
                            {{ auth.role }}
                        </label>
                        <!-- End Role -->

                        <div class="clearfix"></div>

                        <!-- Breadcrumb -->
                        <nav class="breadcrumb breadcrumb-dash"
                            :class="(locale == 'ar') ? 'dir-rtl' : ''">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="breadcrumb-item">
                                <i class="ti-home p-r-5"></i>
                                {{ $t('projects.dashboard') }}
                            </router-link>
                            <router-link :to="{ name: refs }" 
                                class="breadcrumb-item text-capitalize">{{ $t('projects.pgReliefs') }}
                            </router-link>
                            <span class="breadcrumb-item active">{{ $t('projects.edit') }}</span>
                        </nav>
                        <!-- End Breadcrumb -->
                       
                    </div>  
                    <!-- End Header -->

            <div v-if="pgLoading" class="row h-100">
                <div class="container text-center">
                    <div class="loader loader-lg ui-mtop-15"></div>
                </div>
            </div> 
                        
            <form v-if="!pgLoading" @submit.prevent="editRow" enctype="multipart/form-data"> 
                <div class="row" :class="(locale == 'ar') ? 'dir-rtl' : ''">

                    <div class="col-md-12">

                        <!-- Content -->
                        <div class="card">
                            <div class="row">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-11 ml-auto mr-auto">

                                    
                                    <!-- Languages -->
                                    <div class="tab-danger">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item">
                                                <a href="#tab-arabic" 
                                                    class="nav-link active show" 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="true">{{ $t('app.arabic') }}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="#tab-english" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('app.english') }}</a>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#tab-others" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('projects.pro_info') }}</a>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#tab-image" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('projects.pro_image') }}</a>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#tab-files" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('projects.files') }}</a>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#tab-videos" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('projects.videos') }}</a>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#tab-gallery" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('projects.gallery') }}</a>
                                            </li>

                                            <li v-if="auth.role == 'مسؤول-قسم-الإغاثات' || auth.role == 'root'"
                                                class="nav-item">
                                                <a href="#tab-approved" 
                                                    class="nav-link " 
                                                    role="tab" 
                                                    data-toggle="tab" 
                                                    aria-selected="false">{{ $t('app.relief_approved') }}</a>
                                            </li>
                                            
                                        </ul>

                                        <div class="tab-content">
                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab-english">

                                                <div class="p-v-20">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.pro_name') }}</label>
                                                        <input type="text"
                                                            class="form-control" 
                                                            v-model="row.title_en">
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.pro_desc') }}</label>
                                                        <editor
                                                            id="editor1"
                                                            v-model="row.body_en"
                                                            :api-key="editor.api_key"
                                                            :init="{
                                                                height: 300,
                                                                menubar: editor.menubar,
                                                                plugins: editor.plugins,
                                                                toolbar: editor.toolbar
                                                            }"
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.achievements') }}</label>
                                                        <editor
                                                            id="editor3"
                                                            v-model="row.achievements_en"
                                                            :api-key="editor.api_key"
                                                            :init="{
                                                                height: 300,
                                                                menubar: editor.menubar,
                                                                plugins: editor.plugins,
                                                                toolbar: editor.toolbar
                                                            }"
                                                        />
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.slug') }}</label>
                                                        <input type="text" 
                                                            class="form-control text-lowercase" 
                                                            v-on:keypress="isLetter($event)"
                                                            v-model="row.slug_en"
                                                            @keydown.space.prevent 
                                                            @paste="onSlugPaste"
                                                            disabled="true"
                                                            @change="onSlugChange(false)">
                                                            <p style="font-size: 11px;font-style: italic">
                                                                english only
                                                            </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div role="tabpanel" 
                                                class="tab-pane fade active show" 
                                                id="tab-arabic">
                                                
                                                <div class=" p-v-20">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.pro_name') }}</label>
                                                        <input type="text"
                                                            class="form-control" 
                                                            v-model="row.title_ar">
                                                    </div>
                                                    <!-- <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.slug') }}</label>
                                                        <input type="text" 
                                                            class="form-control text-lowercase" 
                                                            v-on:keypress="isLetter($event)"
                                                            v-model="row.slug_ar"
                                                            @keydown.space.prevent 
                                                            @paste="onSlugPaste"
                                                            @change="onSlugChange(false)">
                                                            <p style="font-size: 11px;font-style: italic">
                                                                english only
                                                            </p>
                                                    </div> -->
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.pro_desc') }}</label>
                                                        <editor
                                                            id="editorar1"
                                                            v-model="row.body_ar"
                                                            :api-key="editor.api_key"
                                                            :init="{
                                                                height: 300,
                                                                menubar: editor.menubar,
                                                                plugins: editor.plugins,
                                                                toolbar: editor.toolbar
                                                            }"
                                                        />
                                                    </div>
                                                   
                                                    <div class="form-group">
                                                        <label class="control-label">{{ $t('projects.achievements') }}</label>
                                                        <editor
                                                            id="editorar3"
                                                            v-model="row.achievements_ar"
                                                            :api-key="editor.api_key"
                                                            :init="{
                                                                height: 300,
                                                                menubar: editor.menubar,
                                                                plugins: editor.plugins,
                                                                toolbar: editor.toolbar
                                                            }"
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab-gallery">
                                                <div class="p-v-20">
                                                   
                                                    <!-- For Loop -->
                                                    <div v-for="i in row.gallery_key" 
                                                        :key="i" 
                                                        :class="(i == 0) ? 'hidden' : ''"
                                                        :id="'frm_'+i">

                                                    <div class="row col-12">
                                                    <div class="row col-11">
                                                        
                                                        <div class="col-12 form-group">
                                                            <label class="control-label"> {{ $t('projects.video_url') }}</label>
                                                            <input class="form-control" 
                                                                type="text"
                                                                v-model="row.gallery_video[i]">
                                                        </div>
                                                        <div class="col-12 form-group">
                                                            <label class="control-label">{{ $t('projects.orUpload_image') }}</label>
                                                            <p>
                                                            <img v-if="row.gallery_preview[i]" 
                                                                :src="row.gallery_preview[i]"
                                                                style="max-width: 100%">
                                                            </p>
                                                            <input class="form-control" 
                                                                type="file"
                                                                v-on:change="onGalleryImageChange($event, i)">
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                        <div v-if="i != 0" class="col-12 form-group">
                                                            <button type="button"
                                                                class="btn btn-danger btn-rounded btn-sm btn-bold ui-mt30"
                                                                @click="removeOption(i)">
                                                                <span class="icon-holder">
                                                                    <i class="mdi mdi-delete"></i>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <br/><hr><br/>
                                                    </div>
                                                    </div>
                                                    </div>

                                                    <div class="col-12">
                                                        <button type="button" 
                                                            class="btn btn-primary btn-rounded btn-bold pull-right"
                                                            @click="addMore()">
                                                            <span class="icon-holder">
                                                                <i class="mdi mdi-plus-circle"></i>
                                                                {{ $t('app.add_new') }}
                                                            </span>
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>

                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab-files">
                                                <div class="p-v-20">
                                                    
                                                    <div v-for="i in row.file_key" 
                                                        :key="i" 
                                                        :class="(i == 0) ? 'hidden' : ''"
                                                        :id="'frm2_'+i">

                                                    <div class="row col-12">
                                                    <div class="row col-11">
                                                       
                                                        <div class="col-12 form-group">
                                                            <label class="control-label"> {{ $t('projects.upload_pdf') }}</label>
                                                                <p><a  :href="row.file_path[i]"
                                                                    type="button" 
                                                                    target="_blank" 
                                                                    class="btn btn-primary btn-sm"
                                                                    style="color: #fff">
                                                                    مشاهدة الملف
                                                                </a></p>
                                                            <input class="form-control" 
                                                                type="file"
                                                                v-on:change="onFileChange($event, i)">
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                        <div v-if="i != 0" class="col-12 form-group">
                                                            <button type="button"
                                                                class="btn btn-danger btn-rounded btn-sm btn-bold ui-mt30"
                                                                @click="removeOption2(i)">
                                                                <span class="icon-holder">
                                                                    <i class="mdi mdi-delete"></i>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <br/><hr><br/>
                                                    </div>
                                                    </div>
                                                    </div>

                                                    <div class="col-12">
                                                        <button type="button" 
                                                            class="btn btn-primary btn-rounded btn-bold pull-right"
                                                            @click="addMore2()">
                                                            <span class="icon-holder">
                                                                <i class="mdi mdi-plus-circle"></i>
                                                                {{ $t('app.add_new') }}
                                                            </span>
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>

                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab-videos">
                                                <div class="p-v-20">
                                                    <div v-for="i in row.video_key" 
                                                        :key="i" 
                                                        :class="(i == 0) ? 'hidden' : ''"
                                                        :id="'frm3_'+i">

                                                    <div class="row col-12">
                                                    <div class="row col-11">
                                                        
                                                        <div class="col-12 form-group">
                                                            <label class="control-label"> {{ $t('projects.video_url') }}</label>
                                                            <input class="form-control" 
                                                                type="text"
                                                                v-model="row.video_path[i]">
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                        <div v-if="i != 0" class="col-12 form-group">
                                                            <button type="button"
                                                                class="btn btn-danger btn-rounded btn-sm btn-bold ui-mt30"
                                                                @click="removeOption3(i)">
                                                                <span class="icon-holder">
                                                                    <i class="mdi mdi-delete"></i>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <br/><hr><br/>
                                                    </div>
                                                    </div>
                                                    </div>

                                                    <div class="col-12">
                                                        <button type="button" 
                                                            class="btn btn-primary btn-rounded btn-bold pull-right"
                                                            @click="addMore3()">
                                                            <span class="icon-holder">
                                                                <i class="mdi mdi-plus-circle"></i>
                                                                {{ $t('app.add_new') }}
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div role="tabpanel" 
                                                    class="tab-pane fade in" 
                                                    id="tab-image">

                                                    <div class="p-v-20">
                                                        <div class="form-group">
                                                            <label class="control-label">
                                                                <button v-if="row.preview"
                                                                    type="button" 
                                                                    class="btn btn-danger btn-sm btn-rounded btn-bold"
                                                                    style="margin-top: 5px" 
                                                                    @click="row.preview = ''; row.image=''">
                                                                    {{ $t('projects.remove') }}
                                                                </button>
                                                            </label>
                                                            <p v-if="row.preview">
                                                                <img :src="row.preview" 
                                                                style="max-width: 100%;height: 200px">
                                                            </p>
                                                            <p><br/></p>
                                                            <div class="btn btn-primary btn-sm file">
                                                                <i class="fa fa-upload"></i>
                                                                {{ $t('projects.pro_image_up') }}
                                                                <input type="file"
                                                                    ref="myDropify"
                                                                    accept="image/*"
                                                                     v-on:change="onImageChange">
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>



                                                <div role="tabpanel" 
                                                    class="tab-pane fade in" 
                                                    id="tab-others">
                                                    <div class="p-v-20 col-12 row">



                                                        <div class="form-group col-3">
                                                            <label class="control-label">{{ $t('projects.country') }}</label>
                                                            <div v-if="countryLoading" class="text-center">
                                                                <div class="loader loader-xs"></div>
                                                            </div>
                                                            <select v-if="!countryLoading" 
                                                                    class="form-control" 
                                                                    v-model="row.country_id"
                                                                    @change="fetchCities()">
                                                                <option value="">{{ $t('projects.select_country') }}</option>
                                                                <option v-for="(con, index) in countries" 
                                                                        :key="index"
                                                                        :value="con.id">
                                                                        {{ con.name }}
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <div class="form-group col-3">
                                                            <label class="control-label">{{ $t('projects.city') }}</label>
                                                            <div v-if="cityLoading" class="text-center">
                                                                <div class="loader loader-xs"></div>
                                                            </div>
                                                            <select v-if="!cityLoading" 
                                                                    class="form-control" 
                                                                    v-model="row.city_id">
                                                                <option value="">{{ $t('projects.select_city') }}</option>
                                                                <option v-for="(city, idx) in cities"
                                                                        :key="idx"
                                                                        :value="city.id">
                                                                        {{ city.name }}
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <div class="form-group col-6">
                                                            <label class="control-label">
                                                                {{ $t('projects.target') }}
                                                            </label>
                                                            <input class="form-control" 
                                                                    type="number"
                                                                    min="1"
                                                                    v-model="row.target">
                                                        </div>



                                                        <div class="form-group col-6">
                                                             <div class="form-group">
                                                                <label class="control-label">{{ $t('projects.pgVolunteers') }}</label>
                                                                <div v-if="volunteersLoading" class="text-center">
                                                                    <div class="loader loader-xs"></div>
                                                                </div>
                                                                <multiselect v-if="!volunteersLoading"
                                                                    id="multiselect"
                                                                    ref="multiselectRef"
                                                                    autocomplete="on"
                                                                    v-model="row.volunteersValues" 
                                                                    :options="volunteersOptions" 
                                                                    :multiple="true"
                                                                    :close-on-select="false" 
                                                                    :clear-on-select="false" 
                                                                    :hide-selected="true" 
                                                                    :preserve-search="true" 
                                                                    :taggable="false"
                                                                    :placeholder="$t('projects.choose_volunteers')"
                                                                    :preselect-first="false">
                                                               </multiselect>
                                                            </div>
                                                        </div>

                                                        


                                                <div class="p-v-20 col-12 row">

                                                    <div class="form-group col-6">
                                                        <label class="control-label">
                                                            {{ $t('projects.google_maps') }}
                                                        </label>
                                                        <a href="https://www.google.com/maps" 
                                                            target="_blank"
                                                            style="float: left;font-family: Arial">Open Google maps
                                                        </a>
                                                        <input class="form-control" 
                                                            type="text"
                                                            v-model="row.google_maps">
                                                    </div>
                                                    <div class="col-6">
                                                        
                                                        <div class="form-group col-6">
                                                            <p>
                                                            <input type="checkbox"
                                                                    v-model="row.allow_custome_donation">
                                                            <label class="control-label">
                                                                &nbsp;{{ $t('projects.allow_custom') }}
                                                            </label>
                                                            </p>

                                                            <p>
                                                            <input type="checkbox"
                                                                    v-model="row.allow_donate_other_amount">
                                                            <label class="control-label">
                                                                &nbsp;{{ $t('projects.allow_other_donate') }}
                                                            </label>
                                                            </p>

                                                            <p>
                                                            <input type="checkbox"
                                                                    v-model="row.allow_donate_all_donation">
                                                            <label class="control-label">
                                                                &nbsp;{{ $t('projects.allow_donate_all') }}
                                                            </label>
                                                            </p>

                                                        </div>

                                                    </div>
                                                    <div class="form-group col-6">
                                                        <label class="control-label">
                                                            {{ $t('projects.sort') }}
                                                        </label>
                                                        <input class="form-control" 
                                                            type="number"
                                                            min="0" 
                                                            v-model.number="row.sort">
                                                    </div>
                                                    <div class="col-6">
                                                        <div v-if="row.allow_custome_donation" class="form-group col-12 row">
                                                            <div class="col-6 form-group">
                                                                <input type="text" 
                                                                        class="form-control"
                                                                        :placeholder="$t('projects.donation_option1')"
                                                                        v-model="row.donation_option1">
                                                            </div>
                                                            <div class="col-6 form-group">
                                                                <input type="text" 
                                                                        class="form-control"
                                                                        :placeholder="$t('projects.donation_option2')"
                                                                        v-model="row.donation_option2">
                                                            </div>
                                                            <div class="col-6 form-group">
                                                                <input type="text" 
                                                                        class="form-control"
                                                                        :placeholder="$t('projects.donation_option3')"
                                                                        v-model="row.donation_option3">
                                                            </div>
                                                            <div class="col-6 form-group">
                                                                <input type="text" 
                                                                        class="form-control"
                                                                        :placeholder="$t('projects.donation_option4')"
                                                                        v-model="row.donation_option4">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="form-group">
                                                        <div class="m-b-10">  
                                                            <label class="control-label">
                                                                {{ $t('projects.status') }}
                                                            </label>
                                                        </div>   
                                                        <div class="radio d-inline m-r-15">
                                                            <input id="statusRadio1" 
                                                                type="radio"
                                                                v-model="row.status"
                                                                value="1" 
                                                                :checked="row.status">
                                                            <label for="statusRadio1"> {{ $t('app.active') }} </label>
                                                        </div>
                                                        <div class="radio d-inline m-r-15">
                                                            <input id="statusRadio2" 
                                                                type="radio"
                                                                v-model="row.status"
                                                                value="0"
                                                                :checked="row.status">
                                                            <label for="statusRadio2"> 
                                                                {{ $t('app.inactive') }} 
                                                            </label>
                                                        </div>
                                                </div>

                                                </div>
                                            </div>



                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab-approved">

                                                <div class="p-v-20">
                                                    <div class="form-group">
                                                        <div class="form-group">
                                                            <div class="m-b-10">  
                                                                <label class="control-label">
                                                                    {{ $t('app.relief_approved') }}
                                                                </label>
                                                            </div>   
                                                            <div class="radio d-inline m-r-15">
                                                                <input id="statusRadio13" 
                                                                    type="radio"
                                                                    v-model="row.supervisor_approve"
                                                                    value="1" 
                                                                    :checked="row.supervisor_approve">
                                                                <label for="statusRadio13"> 
                                                                {{ $t('app.approve') }} 
                                                            </label>
                                                            </div>
                                                            <div class="radio d-inline m-r-15">
                                                                <input id="statusRadio23" 
                                                                    type="radio"
                                                                    v-model="row.supervisor_approve"
                                                                    value="2"
                                                                    :checked="row.supervisor_approve">
                                                                <label for="statusRadio23"> 
                                                                    {{ $t('app.unapprove') }} 
                                                                </label>
                                                            </div>
                                                            <p><br/></p>
                                                            <div class="form-group">
                                                                <label for="statusRadio13"> 
                                                                    {{ $t('app.incase_unapprove') }} 
                                                                </label>
                                                                <textarea
                                                                    style="margin-top:10px"
                                                                    rows="7"
                                                                    class="form-control"
                                                                    v-model="row.supervisor_reject_msg">
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div role="tabpanel" 
                                                class="tab-pane fade in" 
                                                id="tab-approved">

                                                <div class="p-v-20">
                                                    <div class="form-group">
                                                        <div class="form-group">
                                                            <div class="m-b-10">  
                                                                <label class="control-label">
                                                                    {{ $t('app.orphan_supervisor') }}
                                                                </label>
                                                            </div>   
                                                            <div class="radio d-inline m-r-15">
                                                                <input id="statusRadio13" 
                                                                    type="radio"
                                                                    v-model="row.supervisor_approve"
                                                                    value="1" 
                                                                    :checked="row.supervisor_approve">
                                                                <label for="statusRadio13"> 
                                                                {{ $t('app.approve') }} 
                                                            </label>
                                                            </div>
                                                            <div class="radio d-inline m-r-15">
                                                                <input id="statusRadio23" 
                                                                    type="radio"
                                                                    v-model="row.supervisor_approve"
                                                                    value="2"
                                                                    :checked="row.supervisor_approve">
                                                                <label for="statusRadio23"> 
                                                                    {{ $t('app.unapprove') }} 
                                                                </label>
                                                            </div>
                                                            <p><br/></p>
                                                            <div class="form-group">
                                                                <label for="statusRadio13"> 
                                                                    {{ $t('app.incase_unapprove') }} 
                                                                </label>
                                                                <textarea
                                                                    style="margin-top:10px"
                                                                    rows="7"
                                                                    class="form-control"
                                                                    v-model="row.supervisor_reject_msg">
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <!-- End Languages -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Content -->


                        <div class="form-group">
                            <button class="btn btn-dark btn-size" :disabled="btnLoading">
                                <span v-if="btnLoading">
                                    <div class="loader loader-xs loader-center"></div>
                                </span>
                                <span v-if="!btnLoading">{{ $t('projects.update') }}</span>
                            </button>
                            <button type="button" @click="cancel" :disabled="btnLoading"
                                class="btn btn-default btn-size p-10">
                                {{ $t('projects.cancel') }}
                            </button>
                        </div>  

                        

                    </div>
                </div>
            </form>


                </div>
            </div>

        <Footer></Footer>
        </div>

    </div>
</template>

<script>
  import Header from '../layouts/Header.vue';
  import QuickView from '../layouts/QuickView.vue';
  import Footer from '../layouts/Footer.vue';
  import Editor from '@tinymce/tinymce-vue';
  import iziToast from 'izitoast';
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'Edit',
    components: {
      Header,
      QuickView,
      Footer,
      Editor,
       Multiselect
    },
    mounted() {},
    data() {
      return {
        //
        auth: {
            access_token: '',
            role: '',
        },
        row: {
            // row
            slug_en: '',
            title_en: '',
            body_en: '',
            goals_en: '',
            achievements_en: '',
        
            slug_ar: '',
            title_ar: '',
            body_ar: '',
            goals_ar: '',
            achievements_ar: '',

            // Galleries
            galleries: [],
            gallery_key: 0,
            gallery_video: [],
            gallery_preview: [],
            gallery_image: [],

            // Files
            files: [],
            file_key: 0,
            file_path: [],

            // Videos
            videos: [],
            video_key: 0,
            video_path: [],

            // image
            preview: '',
            image: '',

            // status & visiblity
            country_id: '',
            city_id: '',
            target: 0,

            donation_option1: '',
            donation_option2: '',
            donation_option3: '',
            donation_option4: '',
            auto_complete_on_goal: 0,
            allow_custome_donation: 0,
            allow_donate_other_amount: 0,
            allow_donate_all_donation: 0,
            completed: 0,

            google_maps: '',
            sort: 0,
            status: 0,

            contributorsValues: [],
            volunteersValues: [],

            supervisor_approve: 0,
            supervisor_reject_msg: '',
        },
        editor: {
            api_key: window.editor_apiKey,
            menubar: window.editor_menubar,
            plugins:[window.editor_plugins],
            toolbar: window.editor_toolbar,
        },

        contributorsLoading: true,
        contributorsOptions: [],

        volunteersLoading: true,
        volunteersOptions: [],

        countryLoading: true,
        countries: [],

        cityLoading: false,
        cities: [],

        pgLoading: true,
        btnLoading: false,

        isClicked: true,
        locale: 'ar',
        refs: 'reliefs',
      }
    },
    computed: {},
    created() {
      // access_token & Role
      if(localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale');
      }
      if(localStorage.getItem('access_token')) {
        this.auth.access_token = localStorage.getItem('access_token');
      }
      if(localStorage.getItem('role')) {
        this.auth.role = localStorage.getItem('role');
      }

      //
      this.fetchRow();
    },
    methods: {

        // fetch Row
        fetchRow() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/'+this.refs+'/'+this.$route.params.id,
                method: 'GET',
                data: {}
            }
            this.$axios(options)
            .then(res => {
                this.pgLoading = false;

                // row
                this.row.title_en = res.data.row.en.title;
                this.row.slug_en = res.data.row.en.slug;
                this.row.body_en = res.data.row.en.body;
                this.row.goals_en = res.data.row.en.goals;
                this.row.achievements_en = res.data.row.en.achievements;

                this.row.title_ar = res.data.row.ar.title;
                this.row.slug_ar = res.data.row.ar.slug;
                this.row.body_ar = res.data.row.ar.body;
                this.row.goals_ar = res.data.row.ar.goals;
                this.row.achievements_ar = res.data.row.ar.achievements;

                // files
                this.row.file_key = res.data.row.files.length;
                    if(res.data.row.files) {
                        for(let i = 1; i <= this.row.file_key; i++) {
                            let x = i-1;
                            this.row.file_path[i] = (res.data.row.files[x])
                                                        ? res.data.row.files[x].file_url
                                                        : '';
                        }
                    }

                // videos
                this.row.video_key = res.data.row.videos.length;
                    if(res.data.row.videos) {
                        for(let i = 1; i <= this.row.video_key; i++) {
                            let x = i-1;
                            this.row.video_path[i] = (res.data.row.videos[x])
                                                        ? res.data.row.videos[x].video_url
                                                        : '';
                        }
                    }

                // galleries
                this.row.gallery_key = res.data.row.galleries.length;
                    if(res.data.row.galleries) {
                        for(let i = 1; i <= this.row.gallery_key; i++) {
                            let x = i-1;
                            this.row.gallery_video[i] = (res.data.row.galleries[x])
                                                        ? res.data.row.galleries[x].video_url
                                                        : '';
                            this.row.gallery_preview[i] = (res.data.row.galleries[x])
                                                        ? res.data.row.galleries[x].image_url
                                                        : null;
                            this.row.gallery_image[i] = (res.data.row.galleries[x])
                                                        ? res.data.row.galleries[x].image_url
                                                        : null;
                        }
                    }
                
                // image
                this.row.preview = (res.data.row.image) ? res.data.row.image.url : null;
                this.row.image = (res.data.row.image) ? res.data.row.image.url : null;

                this.row.country_id = (res.data.row.country) ? res.data.row.country.id : '';
                this.row.city_id = (res.data.row.city) ? res.data.row.city.id : '';

                //this.row.contributorsValues = res.data.row.contributorsValues;
                this.row.volunteersValues = res.data.row.volunteersValues;

                // status & visiblity
                this.row.target = res.data.row.target;

                this.row.donation_option1 = res.data.row.donation_option1;
                this.row.donation_option2 = res.data.row.donation_option2;
                this.row.donation_option3 = res.data.row.donation_option3;
                this.row.donation_option4 = res.data.row.donation_option4;

                this.row.auto_complete_on_goal = res.data.row.auto_complete_on_goal;
                this.row.allow_custome_donation = res.data.row.allow_custome_donation;
                this.row.allow_donate_other_amount = res.data.row.allow_donate_other_amount;
                this.row.allow_donate_all_donation = res.data.row.allow_donate_all_donation;
                this.row.completed = res.data.row.completed;

                this.row.google_maps = res.data.row.google_maps;
                this.row.sort = res.data.row.sort;
                this.row.status = res.data.row.status;

                this.row.supervisor_approve = res.data.row.supervisor_approve;
                this.row.supervisor_reject_msg = res.data.row.supervisor_reject_msg;
                
                this.fetchCountries();
                //
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.pgLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });                
                }
            })
            .finally(() => {});
        },

        fetchCountries() {
            this.countryLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/countries',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: true,
                    paginate: 100
                }
            }
            this.$axios(options)
            .then(res => {
                this.countryLoading = false;
                this.countries = res.data.rows;

                //this.fetchContributors();
                this.fetchVolunteers();
                this.fetchCities();
            })
            .catch(() => {})
            .finally(() => {});
        },

        fetchCities() {
            this.cityLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/countries',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: true,
                    paginate: 100,
                    country_id: this.row.country_id,
                }
            }
            this.$axios(options)
            .then(res => {
                this.cityLoading = false;
                this.cities = res.data.rows;
            })
            .catch(() => {})
            .finally(() => {});
        },

        fetchVolunteers() {
            this.volunteersLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/volunteers',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: true,
                    paginate: 100
                }
            }
            this.$axios(options)
            .then(res => {
                this.volunteersLoading = false;
                this.volunteersOptions = res.data.data;
            })
            .catch(() => {})
            .finally(() => {});
        },


        fetchContributors() {
            this.contributorsLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/contributors',
                method: 'GET',
                data: {},
                params: {
                    locale: this.locale,
                    status: true,
                    paginate: 100
                }
            }
            this.$axios(options)
            .then(res => {
                this.contributorsLoading = false;
                this.contributorsOptions = res.data.data;
            })
            .catch(() => {})
            .finally(() => {});
        },

        // edit Row
        editRow() {
            this.isClicked = false; // disabled alert msg
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };

            // galleries
            for( let i = 1; i <= this.row.gallery_key; i++ ) {
                this.row.galleries[i] = {
                    'gallery_video' : this.row.gallery_video[i],
                    'gallery_image' : this.row.gallery_image[i],
                }
            }

            // files
            for( let i = 1; i <= this.row.file_key; i++ ) {
                this.row.files[i] = {
                    'file_path' : this.row.file_path[i]
                }
            }

            // videos
            for( let i = 1; i <= this.row.video_key; i++ ) {
                this.row.videos[i] = {
                    'video_path' : this.row.video_path[i]
                }
            }

            const options = {
                url: window.baseURL+'/'+this.refs+'/'+this.$route.params.id,
                method: 'PUT',
                data: {
                    // row
                    en: {
                        slug: this.row.slug_en,
                        title: this.row.title_en,
                        body: this.row.body_en,
                        goals: this.row.goals_en,
                        achievements: this.row.achievements_en
                    },
                    ar: {
                        slug: this.row.slug_ar,
                        title: this.row.title_ar,
                        body: this.row.body_ar,
                        goals: this.row.goals_ar,
                        achievements: this.row.achievements_ar
                    },
                    
                    galleries: this.row.galleries,
                    files: this.row.files,
                    videos: this.row.videos,

                    //contributors: this.row.contributorsValues,
                    volunteers: this.row.volunteersValues,

                    // image
                    image: this.row.image,

                    // status & visibility
                    country_id: this.row.country_id,
                    city_id: this.row.city_id,
                    target: this.row.target,

                    donation_option1: this.row.donation_option1,
                    donation_option2: this.row.donation_option2,
                    donation_option3: this.row.donation_option3,
                    donation_option4: this.row.donation_option4,

                    allow_custome_donation: this.row.allow_custome_donation ? 1 : 0,
                    allow_donate_other_amount: this.row.allow_donate_other_amount ? 1 : 0,
                    allow_donate_all_donation: this.row.allow_donate_all_donation ? 1 : 0,

                    google_maps: this.row.google_maps,
                    sort: this.row.sort,
                    status: this.row.status,

                    supervisor_approve: this.row.supervisor_approve,
                    supervisor_reject_msg: this.row.supervisor_reject_msg
                }
            }
        
            this.$axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: '',
                    message: 'تم التعديل بنجاح',
                });
                this.$router.push({ name: this.refs });
                this.isClicked = true; // enabled alert msg
            })
            .catch(err => {
                // 403 Forbidden
                if(err.response && err.response.status == 403) {
                    this.removeLocalStorage();
                    this.$router.push({ name: 'forbidden' });

                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
                this.isClicked = true; // enabled alert msg
            })
            .finally(() => { })
        },

        // remove LocalStorage
        removeLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('avatar');
            localStorage.removeItem('role');
        },

         // Slug Change
        onTitleChange() {
            this.onSlugChange(this.row.title_en);
        },
        onSlugPaste(){
            let str = this.row.slug_en;
            this.onSlugChange(str);
        },
        onSlugChange(str){
            if(!str) {
                let str = this.row.slug_en;
                this.row.slug_en = str.replace(/\s+/g, '-');
                this.row.slug_ar = str.replace(/\s+/g, '-');
            } else {
                this.row.slug_en = str.replace(/\s+/g, '-');
                this.row.slug_ar = str.replace(/\s+/g, '-');
            }
        },


        // add more
        addMore() {
            this.row.gallery_key++;
        },
        removeOption(i) {
            document.querySelector('#frm_'+i).remove();
            this.row.gallery_video[i] = '';
            this.row.gallery_preview[i] = '';
            this.row.gallery_image[i] = '';
        },
        onGalleryImageChange(e, i){
            const file = e.target.files[0];
            this.row.gallery_preview[i] = URL.createObjectURL(file);
            this.createBase64Gallery(file, i);
        },
        createBase64Gallery(fileObject, i){
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);
            reader.onload = e =>{
                this.row.gallery_image[i] = e.target.result;
            };
        },


        addMore2() {
            this.row.file_key++;
        },
        removeOption2(i) {
            document.querySelector('#frm2_'+i).remove();
            this.row.file_path[i] = '';
        },
        onFileChange(e, i){
            const file = e.target.files[0];
            this.createBase64File(file, i);
        },
        createBase64File(fileObject, i){
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);
            reader.onload = e =>{
                this.row.file_path[i] = e.target.result;
            };
        },


        addMore3() {
            this.row.video_key++;
        },
        removeOption3(i) {
            document.querySelector('#frm3_'+i).remove();
            this.row.video_path[i] = '';
        },

        // Upload image
        onImageChange(e){
            const file = e.target.files[0];
            this.row.preview = URL.createObjectURL(file);
            this.createBase64Image(file);
        },
        createBase64Image(fileObject){
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);
            reader.onload = e =>{
                this.row.image = e.target.result;
            };
        },


        isLetter(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        },

        cancel() {
            if(confirm('هل تريد المغادرة ؟')) {
                this.$router.push({ name: this.refs })
            }
        },
    },

        beforeRouteLeave(to, from, next) {
            if(this.isClicked) {
                if(confirm('هل تريد المغادرة ؟')) {
                    next();
                }
            }
        },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="">
</style>